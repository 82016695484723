import {
  LOAD_MEAL_CAL,
  LOAD_MEAL_CAL_SUCCESS,
  LOAD_MEAL_CAL_ERROR,
  DATE_SUSPEND,
  DATE_SUSPEND_SUCCESS,
  DATE_SUSPEND_ERROR,
  DATE_UN_SUSPEND,
  DATE_UN_SUSPEND_SUCCESS,
  DATE_UN_SUSPEND_ERROR,
} from "../Actions";

// Meal Cal
export const loadMealCal = (plan) => ({
  type: LOAD_MEAL_CAL,
  payload: plan,
});
export const loadMealCalSuccess = (_data) => ({
  type: LOAD_MEAL_CAL_SUCCESS,
  payload: _data,
});
export const loadMealCalError = (message) => ({
  type: LOAD_MEAL_CAL_ERROR,
  payload: { message },
});

// dateSuspend
export const dateSuspend = (plan) => ({
  type: DATE_SUSPEND,
  payload: plan,
});
export const dateSuspendSuccess = (_data) => ({
  type: DATE_SUSPEND_SUCCESS,
  payload: _data,
});
export const dateSuspendError = (message) => ({
  type: DATE_SUSPEND_ERROR,
  payload: { message },
});

// dateUnSuspend
export const dateUnSuspend = (plan) => ({
  type: DATE_UN_SUSPEND,
  payload: plan,
});
export const dateUnSuspendSuccess = (_data) => ({
  type: DATE_UN_SUSPEND_SUCCESS,
  payload: _data,
});
export const dateUnSuspendError = (message) => ({
  type: DATE_UN_SUSPEND_ERROR,
  payload: { message },
});
