import {
    GET_MY_PACKAGE,
    GET_MY_PACKAGE_SUCCESS,
    GET_MY_PACKAGE_ERROR,
    SAVE_EX_SUB_DATA_LOCAL,
    SAVE_EX_SUB_DATA_LOCAL_SUCCESS,
    SAVE_EX_SUB_DATA_LOCAL_ERROR,
    ADD_DAYS_TO_PACKAGE,
    ADD_DAYS_TO_PACKAGE_SUCCESS,
    ADD_DAYS_TO_PACKAGE_ERROR,
} from '../Actions';

// My Package
export const getMyPackage = () => ({
    type: GET_MY_PACKAGE
});
export const getMyPackageSuccess = (user) => ({
    type: GET_MY_PACKAGE_SUCCESS,
    payload: user
});
export const getMyPackageError = (message) => ({
    type: GET_MY_PACKAGE_ERROR,
    payload: { message }
});

// Save ex sub data local
export const saveExSubLocal = (data) => ({
    type: SAVE_EX_SUB_DATA_LOCAL,
    payload: data
});
export const saveExSubLocalSuccess = (user) => ({
    type: SAVE_EX_SUB_DATA_LOCAL_SUCCESS,
    payload: user
});
export const saveExSubLocalError = (message) => ({
    type: SAVE_EX_SUB_DATA_LOCAL_ERROR,
    payload: { message }
});


// Save ex sub data local
export const addDaysToPackage = (data, navigate, dispatch, package_name) => ({
    type: ADD_DAYS_TO_PACKAGE,
    payload: { data, navigate, dispatch, package_name }
});
export const addDaysToPackageSuccess = (user) => ({
    type: ADD_DAYS_TO_PACKAGE_SUCCESS,
    payload: user
});
export const addDaysToPackageError = (message) => ({
    type: ADD_DAYS_TO_PACKAGE_ERROR,
    payload: { message }
});