import {
    USER_DETAILS,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_ERROR,
    UPDATE_PERSONAL_INFO,
    UPDATE_PERSONAL_INFO_SUCCESS,
    UPDATE_PERSONAL_INFO_ERROR,
    UPDATE_CALORIE,
    UPDATE_CALORIE_SUCCESS,
    UPDATE_CALORIE_ERROR,
    ADD_ADDRESS,
    ADD_ADDRESS_SUCCESS,
    ADD_ADDRESS_ERROR,
    SET_PRIMARY_ADDRESS,
    SET_PRIMARY_ADDRESS_SUCCESS,
    SET_PRIMARY_ADDRESS_ERROR,
    GET_USER_ADDRESS_ONLY,
    GET_USER_ERROR_ADDRESS_ONLY,
    GET_USER_SUCCESS_ADDRESS_ONLY,
    SAVE_PHYSICAL_DATA,
    SAVE_PHYSICAL_DATA_SUCCESS,
    SAVE_PHYSICAL_DATA_ERROR,
    GET_CURRENT_USER,
    GET_CURRENT_USER_SUCCESS,
    GET_CURRENT_USER_ERROR,
    ADD_TOADY_USER_WEIGHT,
    ADD_TOADY_USER_WEIGHT_SUCCESS,
    ADD_TOADY_USER_WEIGHT_ERROR,
    RESET_USER,
    RESET_USER_SUCCESS,
    RESET_USER_ERROR,
    MEAL_FAV_DONT_SHOW,
    MEAL_FAV_DONT_SHOW_SUCCESS,
    MEAL_FAV_DONT_SHOW_ERROR,
    UPDATE_DELIVERY_TYPE,
    UPDATE_DELIVERY_TYPE_SUCCESS,
    UPDATE_DELIVERY_TYPE_ERROR,
} from '../Actions';

// User
export const userDetails = (payloads) => ({
    type: USER_DETAILS,
    payload: payloads
});
export const userDetailsSuccess = (user) => ({
    type: USER_DETAILS_SUCCESS,
    payload: user
});
export const userDetailsError = (message) => ({
    type: USER_DETAILS_ERROR,
    payload: { message }
});

// Edit Personal info
export const updatePersonalInfo = (payloads) => ({
    type: UPDATE_PERSONAL_INFO,
    payload: payloads
});
export const updatePersonalInfoSuccess = (user) => ({
    type: UPDATE_PERSONAL_INFO_SUCCESS,
    payload: user
});
export const updatePersonalInfoError = (message) => ({
    type: UPDATE_PERSONAL_INFO_ERROR,
    payload: { message }
});

// Edit Personal info
export const updateCalorie = (payloads) => ({
    type: UPDATE_CALORIE,
    payload: payloads
});
export const updateCalorieSuccess = (user) => ({
    type: UPDATE_CALORIE_SUCCESS,
    payload: user
});
export const updateCalorieError = (message) => ({
    type: UPDATE_CALORIE_ERROR,
    payload: { message }
});

// Add Address
export const addAddress = (payloads) => ({
    type: ADD_ADDRESS,
    payload: payloads
});
export const addAddressSuccess = (user) => ({
    type: ADD_ADDRESS_SUCCESS,
    payload: user
});
export const addAddressError = (message) => ({
    type: ADD_ADDRESS_ERROR,
    payload: { message }
});

// Set primary Address
export const setPrimaryAddress = (payloads) => ({
    type: SET_PRIMARY_ADDRESS,
    payload: payloads
});
export const setPrimaryAddressSuccess = (user) => ({
    type: SET_PRIMARY_ADDRESS_SUCCESS,
    payload: user
});
export const setPrimaryAddressError = (message) => ({
    type: SET_PRIMARY_ADDRESS_ERROR,
    payload: { message }
});

// Get user Adress only
export const getUserAddressOnly = () => ({
    type: GET_USER_ADDRESS_ONLY
});
export const getUserAddressOnlySuccess = (user) => ({
    type: GET_USER_SUCCESS_ADDRESS_ONLY,
    payload: user
});
export const getUserAddressOnlyError = (message) => ({
    type: GET_USER_ERROR_ADDRESS_ONLY,
    payload: { message }
});

// Save physical Data
export const savePhysiclData = (data) => ({
    type: SAVE_PHYSICAL_DATA,
    payload: data
});
export const savePhysiclDataSuccess = (user) => ({
    type: SAVE_PHYSICAL_DATA_SUCCESS,
    payload: user
});
export const savePhysiclDataError = (message) => ({
    type: SAVE_PHYSICAL_DATA_ERROR,
    payload: { message }
});

// Get current user
export const getCuurentUser = (data) => ({
    type: GET_CURRENT_USER,
    payload: data
});
export const getCuurentUserSuccess = (user) => ({
    type: GET_CURRENT_USER_SUCCESS,
    payload: user
});
export const getCuurentUserError = (message) => ({
    type: GET_CURRENT_USER_ERROR,
    payload: { message }
});

// Add today user weight
export const addTodayUserWeight = (data, dispatch) => ({
    type: ADD_TOADY_USER_WEIGHT,
    payload: { data, dispatch }
});
export const addTodayUserWeightSuccess = (user) => ({
    type: ADD_TOADY_USER_WEIGHT_SUCCESS,
    payload: user
});
export const addTodayUserWeightError = (message) => ({
    type: ADD_TOADY_USER_WEIGHT_ERROR,
    payload: { message }
});

// Reset user
export const resetUser = () => ({
    type: RESET_USER
});
export const resetUserSuccess = (user) => ({
    type: RESET_USER_SUCCESS,
    payload: user
});

// Add today user weight
export const mealFavDontShow = (data) => ({
    type: MEAL_FAV_DONT_SHOW,
    payload: data
});
export const mealFavDontShowSuccess = (user) => ({
    type: MEAL_FAV_DONT_SHOW_SUCCESS,
    payload: user
});
export const mealFavDontShowError = (message) => ({
    type: MEAL_FAV_DONT_SHOW_ERROR,
    payload: { message }
});

// update delivery type
export const updateDeliveryType = (data) => ({
    type: UPDATE_DELIVERY_TYPE,
    payload: data
});
export const updateDeliveryTypeSuccess = (_data) => ({
    type: UPDATE_DELIVERY_TYPE_SUCCESS,
    payload: _data
});
export const updateDeliveryTypeError = (message) => ({
    type: UPDATE_DELIVERY_TYPE_ERROR,
    payload: { message }
});