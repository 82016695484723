import { all, fork, put, takeEvery } from "redux-saga/effects";
import { alrtTypes } from "../../constants/Utils";
import {
  ADD_ADDRESS,
  ADD_TOADY_USER_WEIGHT,
  GET_CURRENT_USER,
  GET_USER_ADDRESS_ONLY,
  MEAL_FAV_DONT_SHOW,
  RESET_USER,
  SAVE_PHYSICAL_DATA,
  SET_PRIMARY_ADDRESS,
  UPDATE_CALORIE,
  UPDATE_DELIVERY_TYPE,
  UPDATE_PERSONAL_INFO,
  USER_DETAILS,
} from "../Actions";
import { setMessage } from "../AlertMessage/Action";
import { getMyPackage } from "../ExtendSubscription/Action";
import { loadSubscription } from "../Subscription/Action";
import {
  addTodayUserWeightError,
  addTodayUserWeightSuccess,
  getCuurentUser,
  getCuurentUserError,
  getCuurentUserSuccess,
  getUserAddressOnly,
  getUserAddressOnlyError,
  getUserAddressOnlySuccess,
  mealFavDontShow,
  mealFavDontShowError,
  mealFavDontShowSuccess,
  resetUserSuccess,
  savePhysiclDataError,
  savePhysiclDataSuccess,
  setPrimaryAddress,
  setPrimaryAddressError,
  setPrimaryAddressSuccess,
  updateDeliveryType,
  updateDeliveryTypeError,
  updateDeliveryTypeSuccess,
  userDetails,
} from "./Action";
import i18n from "../../i18n/config";

import {
  userDetailsSuccess,
  userDetailsError,
  updatePersonalInfoSuccess,
  updatePersonalInfoError,
  updateCalorieSuccess,
  updateCalorieError,
  addAddressSuccess,
  addAddressError,
} from "./Action";

import {
  addAddressAsync,
  addTodayUserWeightAsync,
  getCurrentUSerAsync,
  getUserAdressOnlyAsync,
  loadUserDetailsAsync,
  mealFavDontShowAsync,
  savePhysicalDataAsync,
  setPrimaryAddressAsync,
  updateCalorieAsync,
  updateDeliveryTypeAsync,
  updatePersonalInfoAsync,
} from "./Apis";

// User
function* user_details({ payload }) {
  const responses = yield loadUserDetailsAsync(payload);
  try {
    const {
      data: { data: allergyList },
    } = responses[0];
    const {
      data: { data: dislikesList },
    } = responses[1];
    // const { data: { data: userData } } = responses[2];
    const {
      data: { data: physicalData },
    } = responses[2];
    const {
      data: { data: allAllergies },
    } = responses[3];
    const {
      data: { data: allDislikes },
    } = responses[4];
    const {
      data: { data: addressList },
    } = responses[5];
    const {
      data: { data: weightHistory },
    } = responses[6];
    if (responses?.length) {
      yield put(
        userDetailsSuccess({
          allergyList: allergyList,
          dislikesList: dislikesList,
          // userData: userData,
          physicalData: physicalData,
          allAllergies: allAllergies,
          allDislikes: allDislikes,
          addressList: addressList,
          weightHistory: weightHistory,
        })
      );
    } else {
      yield put(userDetailsError(new Error(i18n.t("apiResponse.tryAgain"))));
      // yield put(setMessage({
      //     toastMessage: i18n.t("apiResponse.noRecord"),
      //     code: alrtTypes.WARNING
      // }));
      const lang_id = localStorage.getItem("lang_id"),
        lang = localStorage.getItem("lang");
      localStorage.clear();

      localStorage.setItem("lang", lang);
      localStorage.setItem("lang_id", lang_id);
    }
  } catch (err) {
    yield put(userDetailsError(err));
  }
}

export function* watchUserDetails() {
  yield takeEvery(USER_DETAILS, user_details);
}

// Update Personal info
function* updatePersonalInfo({ payload }) {
  const respons = yield updatePersonalInfoAsync(payload);
  const { data } = respons;
  if (payload.type === "efp") {
    try {
      if (respons) yield put(updatePersonalInfoSuccess(payload));
      yield put(
        setMessage({
          toastMessage: i18n.t("apiResponse.updateSuccess"),
          code: alrtTypes.SUCCESS,
        })
      );
    } catch (err) {
      yield put(updatePersonalInfoError(err));
    }
  } else {
    try {
      if (data.data) {
        yield put(updatePersonalInfoSuccess(payload));
        yield put(
          setMessage({
            toastMessage: i18n.t("apiResponse.updateSuccess"),
            code: alrtTypes.SUCCESS,
          })
        );
      } else {
        yield put(updatePersonalInfoError(data.error));
        yield put(
          setMessage({
            toastMessage:
              data.error === "ARE"
                ? i18n.t("apiResponse.userExist")
                : i18n.t("apiResponse.someErr"),
            code: alrtTypes.WARNING,
          })
        );
      }
    } catch (err) {
      yield put(updatePersonalInfoError(err));
    }
  }
}

export function* watchUpdatePersonalInfo() {
  yield takeEvery(UPDATE_PERSONAL_INFO, updatePersonalInfo);
}

// Update Calorie
function* updateCalorie({ payload }) {
  const response = yield updateCalorieAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(updateCalorieSuccess(payload));
    } else {
      yield put(updateCalorieError(data.error));
    }
  } catch (err) {
    yield put(updateCalorieError(data?.error));
  }
}

export function* watchUpdateCalorie() {
  yield takeEvery(UPDATE_CALORIE, updateCalorie);
}

// Add Address
function* addAddress({ payload }) {
  const response = yield addAddressAsync(payload);
  const { data } = response;
  try {
    if (data?.data?.address_master_id) {
      yield put(addAddressSuccess(data.data));
      let d = {
        address_id: data?.data?.address_master_id,
        delivery_method_id: payload?.delivery_method_id,
        delivery_time_id: payload?.delivery_time_id,
      };
      payload.dispatch(updateDeliveryType(d));
      payload.dispatch(getUserAddressOnly());
      payload.dispatch(getMyPackage());
      // if (payload?.isPrimary) {
      //     payload?.dispatch(setPrimaryAddress({ address_id: data?.data?.address_master_id, isPrimary: payload?.isPrimary }))
      // }
    } else {
      yield put(addAddressError(data.error));
    }
  } catch (err) {
    yield put(addAddressError(data?.error));
  }
}

export function* watchAddAddress() {
  yield takeEvery(ADD_ADDRESS, addAddress);
}

// Set Primary Address
function* setPrimary_address({ payload }) {
  const response = yield setPrimaryAddressAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(setPrimaryAddressSuccess(payload));
    } else {
      yield put(setPrimaryAddressError(data.error));
    }
  } catch (err) {
    yield put(setPrimaryAddressError(data?.error));
  }
}

export function* watchSetPrimaryAddress() {
  yield takeEvery(SET_PRIMARY_ADDRESS, setPrimary_address);
}

// get Address only
function* getUser_addressOnly({ payload }) {
  const response = yield getUserAdressOnlyAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(getUserAddressOnlySuccess(data?.data));
    } else {
      yield put(getUserAddressOnlyError(data.error));
    }
  } catch (err) {
    yield put(getUserAddressOnlyError(data?.error));
  }
}

export function* watchGetUserAddressOnly() {
  yield takeEvery(GET_USER_ADDRESS_ONLY, getUser_addressOnly);
}

// Save Physical data
function* savePhysical_data({ payload }) {
  const response = yield savePhysicalDataAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(savePhysiclDataSuccess(payload));
    } else {
      yield put(savePhysiclDataError(data.error));
    }
  } catch (err) {
    yield put(savePhysiclDataError(data?.error));
  }
}

export function* watchSavePhysicalData() {
  yield takeEvery(SAVE_PHYSICAL_DATA, savePhysical_data);
}

// Get current user data
function* current_user({ payload }) {
  const response = yield getCurrentUSerAsync(payload);
  const { data } = response;

  try {
    if (data?.data) {
      yield put(getCuurentUserSuccess(data?.data));
    } else {
      yield put(getCuurentUserError(data?.error));
    }
  } catch (err) {
    yield put(getCuurentUserError(data?.error));
  }
}

export function* watchGetCurrentUser() {
  yield takeEvery(GET_CURRENT_USER, current_user);
}

// Add today user  weight
function* today_user_weight({ payload }) {
  const response = yield addTodayUserWeightAsync(payload.data);
  const { data } = response;
  try {
    if (data.data) {
      yield put(addTodayUserWeightSuccess(payload.data));
    } else {
      yield put(addTodayUserWeightError(data.error));
    }
  } catch (err) {
    yield put(addTodayUserWeightError(data?.error));
  }
}

export function* watchAddTodayUserWeight() {
  yield takeEvery(ADD_TOADY_USER_WEIGHT, today_user_weight);
}

// Meal fav dont show icon
function* meal_fav_dont_show({ payload }) {
  const response = yield mealFavDontShowAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(mealFavDontShowSuccess(payload));
    } else {
      yield put(mealFavDontShowError(data.error));
    }
  } catch (err) {
    yield put(mealFavDontShowError(data?.error));
  }
}

export function* watchMealFavDontShow() {
  yield takeEvery(MEAL_FAV_DONT_SHOW, meal_fav_dont_show);
}

// Update delivery type and time
function* update_delivery_type({ payload }) {
  const response = yield updateDeliveryTypeAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(updateDeliveryTypeSuccess(data.data));
      payload.dispatch(getUserAddressOnly());
    } else {
      yield put(updateDeliveryTypeError(data.error));
    }
  } catch (err) {
    yield put(updateDeliveryTypeError(data?.error));
  }
}

export function* watchUpdateDeliveryType() {
  yield takeEvery(UPDATE_DELIVERY_TYPE, update_delivery_type);
}

// Reset user
function* reset_user() {
  yield put(resetUserSuccess());
}

export function* watchResestUser() {
  yield takeEvery(RESET_USER, reset_user);
}

export default function* rootSaga() {
  yield all([
    fork(watchUserDetails),
    fork(watchUpdatePersonalInfo),
    fork(watchUpdateCalorie),
    fork(watchAddAddress),
    fork(watchSetPrimaryAddress),
    fork(watchGetUserAddressOnly),
    fork(watchSavePhysicalData),
    fork(watchGetCurrentUser),
    fork(watchAddTodayUserWeight),
    fork(watchResestUser),
    fork(watchMealFavDontShow),
    fork(watchUpdateDeliveryType),
  ]);
}
