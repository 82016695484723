import axios from "axios";
import { BASE_URL } from "../../config/ApiConstants";

// Meal list
export const loadMealListAsync1 = async (query) => {
  let formData = new FormData();
  formData.append("package_id", query?.package_id);
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("days_master_id", query?.days_master_id);
  formData.append("mealtype_id", query?.mealtype_id);
  formData.append("week_id", query?.week_id);
  formData.append("user_id", localStorage.getItem("userId"));

  try {
    const data = await axios.post(`${BASE_URL}/meallist`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Meal list
export const loadMealListAsync2 = async (query) => {
  let formData = new FormData();
  formData.append("package_id", query?.package_id);
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("days_master_id", query?.days_master_id);
  formData.append("mealtype_id", query?.mealtype_id);
  formData.append("week_id", query?.week_id);
  formData.append("user_id", localStorage.getItem("userId"));

  try {
    const data = await axios.post(`${BASE_URL}/meallist`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Meal list
export const loadMealListAsync3 = async (query) => {
  let formData = new FormData();
  formData.append("package_id", query?.package_id);
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("days_master_id", query?.days_master_id);
  formData.append("mealtype_id", query?.mealtype_id);
  formData.append("week_id", query?.week_id);
  formData.append("user_id", localStorage.getItem("userId"));

  try {
    const data = await axios.post(`${BASE_URL}/meallist`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Meal list
export const loadMealListAsync4 = async (query) => {
  let formData = new FormData();
  formData.append("package_id", query?.package_id);
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("days_master_id", query?.days_master_id);
  formData.append("mealtype_id", query?.mealtype_id);
  formData.append("week_id", query?.week_id);
  formData.append("user_id", localStorage.getItem("userId"));

  try {
    const data = await axios.post(`${BASE_URL}/meallist`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// My Meal
export const loadMyMealAsync = async (query) => {
  let formData = new FormData();
  formData.append("package_id", query?.package_id);
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("days_master_id", query?.days_master_id);
  if (query?.mealtype_id) formData.append("mealtype_id", query?.mealtype_id);
  formData.append("week_id", query?.week_id);
  formData.append("user_id", localStorage.getItem("userId"));

  try {
    const data = await axios.post(`${BASE_URL}/getMyMeal`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Add Meal
export const addMealAsync = async (data) => {
  let formData = new FormData();
  formData.append("package_id", data?.package_id);
  formData.append("order_id", data?.order_id);
  formData.append("meal_date", data?.meal_date);
  formData.append("meal", JSON.stringify(data?.meal));
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("jwt", localStorage.getItem("token"));

  try {
    const data = await axios.post(`${BASE_URL}/addMeal`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

//  all Meallist
export const allMealsAsync = async (query) => {
  let formData = new FormData();

  formData.append("package_id", query?.package_id);
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("days_master_id", query?.days_master_id);
  formData.append("week_id", query?.week_id);
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("jwt", localStorage.getItem("token"));

  try {
    const data = await axios.post(`${BASE_URL}/meallist`, formData);
    return data;
  } catch (error) {
    return error;
  }
};
// fav Meal
export const favMealAsync = async (query) => {
  let formData = new FormData();
  formData.append("meal_id", query?.meal_id);
  formData.append("status", query?.status);
  formData.append("jwt", localStorage.getItem("token"));
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("jwt", localStorage.getItem("token"));

  try {
    const data = await axios.post(`${BASE_URL}/add_to_fav`, formData);
    return data;
  } catch (error) {
    return error;
  }
};
