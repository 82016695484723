import axios from "axios";
import { BASE_URL } from "../../config/ApiConstants";

// Plans
export const loadCaloriePlanDetailsAsync = async (planQuery) => {
  let formData = new FormData();
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("calorie", planQuery.calorie);
  formData.append("days", planQuery.days);

  try {
    const data = await axios.post(
      `${BASE_URL}/get_packages_by_calorie`,
      formData
    );
    return data;
  } catch (error) {
    return error;
  }
};
