import axios from "axios";
import { BASE_URL } from "../../config/ApiConstants";

// My Package
export const loadMyPackageAsync = async () => {
  let formData = new FormData();
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("jwt", localStorage.getItem("token"));
  try {
    const data = await axios.post(`${BASE_URL}/mypackage`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Add days to pack
export const addDaysToPackAsync = async (formData) => {
  formData.append("jwt", localStorage.getItem("token"));
  try {
    const data = await axios.post(`${BASE_URL}/adddaystopackage`, formData);
    return data;
  } catch (error) {
    return error;
  }
};
