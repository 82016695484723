import React, { useState, useEffect } from "react";
import { Toast } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ErrorIcon, TickIcon } from "../../assets/images/Images";
import { resetMessage } from "../../Store/AlertMessage/Action";
import "./style.scss";

function ToastComponent(props) {
  const [showToast, setToastShow] = useState(true);
  const toggleToastShow = () => setToastShow(!showToast);
  let { message, bg, delay } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(
      () => {
        dispatch(resetMessage());
      },
      delay ? delay : 3000
    );
    return () => clearTimeout(timer);
  }, [dispatch]);

  return (
    <Toast
      className="toast-customize"
      bg={bg}
      show={showToast}
      onClose={toggleToastShow}
      delay={delay ? delay : 3000}
    >
      <Toast.Header closeButton={true}></Toast.Header>
      <Toast.Body className={bg}>
        <span className="icon" bg={bg}>
          {" "}
          <img
            src={bg === "warning" ? ErrorIcon : TickIcon}
            alt="Toast Icon"
          />{" "}
        </span>
        {message}
      </Toast.Body>
    </Toast>
  );
}

export default ToastComponent;
