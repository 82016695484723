// ALERT_MESSAGE
export const RESET_MESSAGE = "RESET_MESSAGE";
export const SET_MESSAGE = "SET_MESSAGE";

/* AUTH */
export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

/* LOGIN */
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

/* FORGOT PASSWORD */
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

/* RESET PASSWORD */
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

/* LOGOUT */
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

/* LOAD PLANS */
export const LOAD_PLANS = "LOAD_PLANS";
export const LOAD_PLANS_SUCCESS = "LOAD_PLANS_SUCCESS";
export const LOAD_PLANS_ERROR = "LOAD_PLANS_ERROR";

/* FAQ */
export const FAQ = "FAQ";
export const FAQ_SUCCESS = "FAQ_SUCCESS";
export const FAQ_ERROR = "FAQ_ERROR";

/* TERMS AND CONDITIONS */
export const TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS";
export const TERMS_AND_CONDITIONS_SUCCESS = "TERMS_AND_CONDITIONS_SUCCESS";
export const TERMS_AND_CONDITIONS_ERROR = "TERMS_AND_CONDITIONS_ERROR";

/* USER DETAILS */
export const USER_DETAILS = "USER_DETAILS";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_ERROR = "USER_DETAILS_ERROR";

/* UPDATE PERSONAL INFO */
export const UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO";
export const UPDATE_PERSONAL_INFO_SUCCESS = "UPDATE_PERSONAL_INFO_SUCCESS";
export const UPDATE_PERSONAL_INFO_ERROR = "UPDATE_PERSONAL_INFO_ERROR";

/* CALCULATE CALORIES */
export const CALCULATE_CALORIES = "CALCULATE_CALORIES";
export const CALCULATE_CALORIES_SUCCESS = "CALCULATE_CALORIES_SUCCESS";
export const CALCULATE_CALORIES_ERROR = "CALCULATE_CALORIES_ERROR";

/* CALORIE PLANS */
export const CALORIE_PLANS = "CALORIE_PLANS";
export const CALORIE_PLANS_SUCCESS = "CALORIE_PLANS_SUCCESS";
export const CALORIE_PLANS_ERROR = "CALORIE_PLANS_ERROR";

/* PLAN DETAILS */
export const PLAN_DETAILS = "PLAN_DETAILS";
export const PLAN_DETAILS_SUCCESS = "PLAN_DETAILS_SUCCESS";
export const PLAN_DETAILS_ERROR = "PLAN_DETAILS_ERROR";

/* SUBSCRIPTION */
export const LOAD_SUBSCRIPTION = "LOAD_SUBSCRIPTION";
export const LOAD_SUBSCRIPTION_SUCCESS = "LOAD_SUBSCRIPTION_SUCCESS";
export const LOAD_SUBSCRIPTION_ERROR = "LOAD_SUBSCRIPTION_ERROR";

/* PROMOCODE */
export const PROMOCODE_IS_VALID = "PROMOCODE_IS_VALID";
export const PROMOCODE_IS_VALID_SUCCESS = "PROMOCODE_IS_VALID_SUCCESS";
export const PROMOCODE_IS_VALID_ERROR = "PROMOCODE_IS_VALID_ERROR";

/* PURCHASE PACKAGE */
export const PURCHASE_PACKAGE = "PURCHASE_PACKAGE";
export const PURCHASE_PACKAGE_SUCCESS = "PURCHASE_PACKAGE_SUCCESS";
export const PURCHASE_PACKAGE_ERROR = "PURCHASE_PACKAGE_ERROR";

/* UPDATE CALORIE */
export const UPDATE_CALORIE = "UPDATE_CALORIE";
export const UPDATE_CALORIE_SUCCESS = "UPDATE_CALORIE_SUCCESS";
export const UPDATE_CALORIE_ERROR = "UPDATE_CALORIE_ERROR";

/* LOAD DASHBOARD */
export const LOAD_DASHBOARD = "LOAD_DASHBOARD";
export const LOAD_DASHBOARD_SUCCESS = "LOAD_DASHBOARD_SUCCESS";
export const LOAD_DASHBOARD_ERROR = "LOAD_DASHBOARD_ERROR";
export const RESET_DASHBOARD = "RESET_DASHBOARD";

/* LOAD MY_MEALS */
export const LOAD_MY_MEALS = "LOAD_MY_MEALS";
export const LOAD_MY_MEALS_SUCCESS = "LOAD_MY_MEALS_SUCCESS";
export const LOAD_MY_MEALS_ERROR = "LOAD_MY_MEALS_ERROR";

/* LOAD MY_MEALS */
export const LOAD_MEAL_CAL = "LOAD_MEAL_CAL";
export const LOAD_MEAL_CAL_SUCCESS = "LOAD_MEAL_CAL_SUCCESS";
export const LOAD_MEAL_CAL_ERROR = "LOAD_MEAL_CAL_ERROR";

/* DATE SUSPEND */
export const DATE_SUSPEND = "DATE_SUSPEND";
export const DATE_SUSPEND_SUCCESS = "DATE_SUSPEND_SUCCESS";
export const DATE_SUSPEND_ERROR = "DATE_SUSPEND_ERROR";

/* MEAL LIST */
export const LOAD_MEAL_LIST = "LOAD_MEAL_LIST";
export const LOAD_MEAL_LIST_SUCCESS = "LOAD_MEAL_LIST_SUCCESS";
export const LOAD_MEAL_LIST_ERROR = "LOAD_MEAL_LIST_ERROR";

/* MY MEAL */
export const LOAD_MY_MEAL = "LOAD_MY_MEAL";
export const LOAD_MY_MEAL_SUCCESS = "LOAD_MY_MEAL_SUCCESS";
export const LOAD_MY_MEAL_ERROR = "LOAD_MY_MEAL_ERROR";

/* ADD MEAL */
export const ADD_MEAL = "ADD_MEAL";
export const ADD_MEAL_SUCCESS = "ADD_MEAL_SUCCESS";
export const ADD_MEAL_ERROR = "ADD_MEAL_ERROR";

/* ADD ADDRESS */
export const ADD_ADDRESS = "ADD_ADDRESS";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_ERROR = "ADD_ADDRESS_ERROR";

/* SET PRIMARY ADDRESS */
export const SET_PRIMARY_ADDRESS = "SET_PRIMARY_ADDRESS";
export const SET_PRIMARY_ADDRESS_SUCCESS = "SET_PRIMARY_ADDRESS_SUCCESS";
export const SET_PRIMARY_ADDRESS_ERROR = "SET_PRIMARY_ADDRESS_ERROR";

/* GET MY PACKAGE */
export const GET_MY_PACKAGE = "GET_MY_PACKAGE";
export const GET_MY_PACKAGE_SUCCESS = "GET_MY_PACKAGE_SUCCESS";
export const GET_MY_PACKAGE_ERROR = "GET_MY_PACKAGE_ERROR";

/* GET USER ADDRESS */
export const GET_USER_ADDRESS_ONLY = "GET_USER_ADDRESS_ONLY";
export const GET_USER_SUCCESS_ADDRESS_ONLY = "GET_USER_SUCCESS_ADDRESS_ONLY";
export const GET_USER_ERROR_ADDRESS_ONLY = "GET_USER_ERROR_ADDRESS_ONLY";

/* SAVE PHYSICAL DATA */
export const SAVE_PHYSICAL_DATA = "SAVE_PHYSICAL_DATA";
export const SAVE_PHYSICAL_DATA_SUCCESS = "SAVE_PHYSICAL_DATA_SUCCESS";
export const SAVE_PHYSICAL_DATA_ERROR = "SAVE_PHYSICAL_DATA_ERROR";

/* SAVE EX SUB DATA LOCAL */
export const SAVE_EX_SUB_DATA_LOCAL = "SAVE_EX_SUB_DATA_LOCAL";
export const SAVE_EX_SUB_DATA_LOCAL_SUCCESS = "SAVE_EX_SUB_DATA_LOCAL_SUCCESS";
export const SAVE_EX_SUB_DATA_LOCAL_ERROR = "SAVE_EX_SUB_DATA_LOCAL_ERROR";

/* ADD DAYS TO PACKAGE */
export const ADD_DAYS_TO_PACKAGE = "ADD_DAYS_TO_PACKAGE";
export const ADD_DAYS_TO_PACKAGE_SUCCESS = "ADD_DAYS_TO_PACKAGE_SUCCESS";
export const ADD_DAYS_TO_PACKAGE_ERROR = "ADD_DAYS_TO_PACKAGE_ERROR";

/* GET CURRENT USER */
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_ERROR = "GET_CURRENT_USER_ERROR";

/* ALL LOAD MEAL LIST */
export const LOAD_MEAL_LIST_SINGLE_SHORT = "LOAD_MEAL_LIST_SINGLE_SHORT";
export const LOAD_MEAL_LIST_SINGLE_SHORT_SUCCESS =
  "LOAD_MEAL_LIST_SINGLE_SHORT_SUCCESS";
export const LOAD_MEAL_LIST_SINGLE_SHORT_ERROR =
  "LOAD_MEAL_LIST_SINGLE_SHORT_ERROR";

/* ADD TODAY USER WEIGHT */
export const ADD_TOADY_USER_WEIGHT = "ADD_TOADY_USER_WEIGHT";
export const ADD_TOADY_USER_WEIGHT_SUCCESS = "ADD_TOADY_USER_WEIGHT_SUCCESS";
export const ADD_TOADY_USER_WEIGHT_ERROR = "ADD_TOADY_USER_WEIGHT_ERROR";

/* RESET USER */
export const RESET_USER = "RESET_USER";
export const RESET_USER_SUCCESS = "RESET_USER_SUCCESS";
export const RESET_USER_ERROR = "RESET_USER_ERROR";

/* RESET MEAL */
export const RESET_MEAL = "RESET_MEAL";
export const RESET_MEAL_SUCCESS = "RESET_MEAL_SUCCESS";
export const RESET_MEAL_ERROR = "RESET_MEAL_ERROR";

/* FAV MEAL */
export const FAV_MEAL = "FAV_MEAL";
export const FAV_MEAL_SUCCESS = "FAV_MEAL_SUCCESS";
export const FAV_MEAL_ERROR = "FAV_MEAL_ERROR";

/* GET END DATE */
export const GET_END_DATE = "GET_END_DATE";
export const GET_END_DATE_SUCCESS = "GET_END_DATE_SUCCESS";
export const GET_END_DATE_ERROR = "GET_END_DATE_ERROR";

/*MEAL FAV DONT SHOW */
export const MEAL_FAV_DONT_SHOW = "MEAL_FAV_DONT_SHOW";
export const MEAL_FAV_DONT_SHOW_SUCCESS = "MEAL_FAV_DONT_SHOW_SUCCESS";
export const MEAL_FAV_DONT_SHOW_ERROR = "MEAL_FAV_DONT_SHOW_ERROR";

// MAKE PROMO NULL
export const MAKE_PROMO_NULL = "MAKE_PROMO_NULL";
export const MAKE_PROMO_NULL_SUCCESS = "MAKE_PROMO_NULL_SUCCESS";
export const MAKE_PROMO_NULL_ERROR = "MAKE_PROMO_NULL_ERROR";

/*UPDATE DELIVERY TIME AND METHOD */
export const UPDATE_DELIVERY_TYPE = "UPDATE_DELIVERY_TYPE";
export const UPDATE_DELIVERY_TYPE_SUCCESS = "UPDATE_DELIVERY_TYPE_SUCCESS";
export const UPDATE_DELIVERY_TYPE_ERROR = "UPDATE_DELIVERY_TYPE_ERROR";

export const DATE_UN_SUSPEND = "DATE_UN_SUSPEND";
export const DATE_UN_SUSPEND_SUCCESS = "DATE_UN_SUSPEND_SUCCESS";
export const DATE_UN_SUSPEND_ERROR = "DATE_UN_SUSPEND_ERROR";
