import { all } from 'redux-saga/effects';

import authSagas from './Auth/Saga';
import homeSaga from './Home/Saga';
import faqSaga from './FAQ/Saga';
import t_cSaga from './TermsAndConditions/Saga';
import userSaga from './User/Saga';
import ccSaga from './CalculateCalories/Saga';
import planDetailsSaga from './PlanDetails/Saga';
import caloriePlanDetailsSaga from './CaloriePlans/Saga';
import subscriptionSaga from './Subscription/Saga';
import dashboardSaga from './Dashboard/Saga';
import mealCal from './MealCal/Saga';
import mealList from './MealList/Saga';
import extendSub from './ExtendSubscription/Saga';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        homeSaga(),
        faqSaga(),
        t_cSaga(),
        userSaga(),
        ccSaga(),
        caloriePlanDetailsSaga(),
        planDetailsSaga(),
        subscriptionSaga(),
        dashboardSaga(),
        mealCal(),
        mealList(),
        extendSub()
    ]);
};
