import {
    FAQ,
    FAQ_SUCCESS,
    FAQ_ERROR,
} from '../Actions';

// Plans
export const faq = (user) => ({
    type: FAQ,
    payload: user
});
export const faqSuccess = (user) => ({
    type: FAQ_SUCCESS,
    payload: user
});
export const faqError = (message) => ({
    type: FAQ_ERROR,
    payload: { message }
});
