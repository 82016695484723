import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { PLAN_DETAILS } from '../Actions';

import {
    planDetailsSuccess,
    planDetailsError
} from './Action';
import { loadPlanDetailsAsync } from './Apis';



// Plan Details
function* loadPlanDetails({ payload }) {
    const response = yield loadPlanDetailsAsync(payload);
    const { data } = response;
    try {
        if (data.data) {
            yield put(planDetailsSuccess(data.data));
        }

        else {
            yield put(planDetailsError(data.error));
        }
    }
    catch (err) {
        yield put(planDetailsError(data?.error));
    }
}

export function* watchLoadPlanDetails() {
    yield takeEvery(PLAN_DETAILS, loadPlanDetails);
}

export default function* rootSaga() {
    yield all([
        fork(watchLoadPlanDetails)
    ]);
}