import axios from "axios";
import moment from "moment";
import { BASE_URL } from "../../config/ApiConstants";

// Plans
export const loadSubscriptionAsync = async (plan_id) => {
  let formData = new FormData();
  formData.append("language_id", localStorage.getItem("lang_id") || 1);
  let formData1 = new FormData();
  formData1.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData1.append("user_id", localStorage.getItem("userId"));
  formData1.append("jwt", localStorage.getItem("token"));

  try {
    const apis = [
      axios.post(`${BASE_URL}/DeliveryTime`, formData),
      axios.post(`${BASE_URL}/DeliveryMethod`, formData),
      axios.post(`${BASE_URL}/useraddresslist`, formData1),
      axios.post(`${BASE_URL}/arealist`, formData),
    ];
    const responses = await Promise.all(apis);
    return responses;
  } catch (error) {
    return error("Something went wrong");
  }
};

// Promocode
export const loadPromocodeIsValidAsync = async (coupen) => {
  let formData = new FormData();
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("coupon_code", coupen);
  formData.append("jwt", localStorage.getItem("token"));

  // localStorage.getItem('ls_langauge_id') ||
  try {
    const data = await axios.post(`${BASE_URL}/verifyCoupon`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Purchase package
export const loadpurchasePackageAsync = async (_data) => {
  _data.append("jwt", localStorage.getItem("token"));
  try {
    const data = await axios.post(`${BASE_URL}/purchasepackage`, _data);
    return data;
  } catch (error) {
    return error;
  }
};

// getEndDate
export const getEndDateAsync = async (data) => {
  let formData = new FormData();
  formData.append("package_for", data?.package_for);
  formData.append("start_date", data?.start_date);
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("jwt", localStorage.getItem("token"));

  try {
    const data = await axios.post(`${BASE_URL}/purchaseorderdate`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// /getExSubEndDate
export const getexSubEndDateAsync = async (data) => {
  let formData = new FormData();
  formData.append("order_master_id", data?.order_master_id);
  formData.append("jwt", localStorage.getItem("token"));
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("no_of_days_add", data?.days);
  try {
    const data = await axios.post(
      `${BASE_URL}/get_extend_subscription_enddate`,
      formData
    );
    return data;
  } catch (error) {
    return error;
  }
};
