import {
    LOAD_MEAL_LIST,
    LOAD_MEAL_LIST_SUCCESS,
    LOAD_MEAL_LIST_ERROR,
    LOAD_MY_MEAL,
    LOAD_MY_MEAL_SUCCESS,
    LOAD_MY_MEAL_ERROR,
    ADD_MEAL,
    ADD_MEAL_SUCCESS,
    ADD_MEAL_ERROR,
    LOAD_MEAL_LIST_SINGLE_SHORT,
    LOAD_MEAL_LIST_SINGLE_SHORT_SUCCESS,
    LOAD_MEAL_LIST_SINGLE_SHORT_ERROR,
    RESET_MEAL,
    RESET_MEAL_SUCCESS,
    FAV_MEAL,
    FAV_MEAL_SUCCESS,
    FAV_MEAL_ERROR,


} from '../Actions';

// Meal list
export const loadMealList = (query) => ({
    type: LOAD_MEAL_LIST,
    payload: query
});
export const loadMealListSuccess = (_data) => ({
    type: LOAD_MEAL_LIST_SUCCESS,
    payload: _data
});
export const loadMealListError = (message) => ({
    type: LOAD_MEAL_LIST_ERROR,
    payload: { message }
});


// My Meal
export const loadMyMeal = (query) => ({
    type: LOAD_MY_MEAL,
    payload: query
});
export const loadMyMealSuccess = (_data) => ({
    type: LOAD_MY_MEAL_SUCCESS,
    payload: _data
});
export const loadMyMealError = (message) => ({
    type: LOAD_MY_MEAL_ERROR,
    payload: { message }
});

// Add Meal
export const addMeal = (query) => ({
    type: ADD_MEAL,
    payload: query
});
export const addMealSuccess = (_data) => ({
    type: ADD_MEAL_SUCCESS,
    payload: _data
});
export const addMealError = (message) => ({
    type: ADD_MEAL_ERROR,
    payload: { message }
});


// Meal list SIngle short
export const mealListSingleShort = (query) => ({
    type: LOAD_MEAL_LIST_SINGLE_SHORT,
    payload: query
});
export const mealListSingleShortSuccess = (_data) => ({
    type: LOAD_MEAL_LIST_SINGLE_SHORT_SUCCESS,
    payload: _data
});
export const mealListSingleShortError = (message) => ({
    type: LOAD_MEAL_LIST_SINGLE_SHORT_ERROR,
    payload: { message }
});

// reset meal
export const resetMeal = () => ({
    type: RESET_MEAL
});
export const resetMealSuccess = (_data) => ({
    type: RESET_MEAL_SUCCESS,
    payload: _data
});

// Fav meal
export const favMeal = (query) => ({
    type: FAV_MEAL,
    payload: query
});
export const favMealSuccess = (_data) => ({
    type: FAV_MEAL_SUCCESS,
    payload: _data
});
export const favMealError = (message) => ({
    type: FAV_MEAL_ERROR,
    payload: { message }
});