import axios from "axios";
import { BASE_URL } from "../../config/ApiConstants";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("es_token")}`,
    "Content-Type": "multipart/form-data",
  },
};

// Signup
export const createUserAsync = async (payload) => {
  let formData = new FormData();
  formData.append("first_name", payload.firstname);
  formData.append("last_name", payload.lastname);
  formData.append("email", payload.email);
  formData.append("mobile_no", payload.mobile_no);
  formData.append("password", payload.password);
  formData.append("device_name", "");
  formData.append("device_token", "");
  formData.append("device_id", "");

  try {
    const data = await axios.post(`${BASE_URL}/userregistration`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Login
export const loginAsync = async (payload) => {
  let formData = new FormData();
  formData.append("email", payload.email ? payload.email : "");
  formData.append("password", payload.password ? payload.password : "");
  formData.append("device_name", "web");
  formData.append("device_token", "");
  formData.append("device_id", "");
  try {
    const data = await axios.post(`${BASE_URL}/userlogin`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Forgot Password
export const forgotPasswordAsync = async (payload) => {
  let formData = new FormData();
  formData.append("email", payload.email);
  try {
    const data = await axios.post(`${BASE_URL}/passwordreset`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Reset Password
export const resetPasswordAsync = async (payload) => {
  if (payload.isForgotPassword) {
    let formData = new FormData();
    formData.append("email", payload.user.email);
    formData.append("new_password", payload?.user?.password);
    try {
      const data = axios.post(`${BASE_URL}/passwordresetform`, formData);
      return data;
    } catch (error) {
      return error;
    }
  } else {
    let formData = new FormData();
    formData.append("new_pass", payload?.user?.new_password);
    formData.append("old_pass", payload?.user?.old_password);
    formData.append("user_id", localStorage.getItem("userId"));
    try {
      const data = axios.post(`${BASE_URL}/changepassword`, formData);
      return data;
    } catch (error) {
      return error;
    }
  }
};
