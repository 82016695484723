import {
    CALCULATE_CALORIES,
    CALCULATE_CALORIES_SUCCESS,
    CALCULATE_CALORIES_ERROR,
   
} from '../Actions';

// Calculate Calorie
export const calculateCalories = (type, value) => ({
    type: CALCULATE_CALORIES,
    payload: { type, value }
});
export const calculateCaloriesSuccess = (user) => ({
    type: CALCULATE_CALORIES_SUCCESS,
    payload: user
});
export const calculateCaloriesError = (message) => ({
    type: CALCULATE_CALORIES_ERROR,
    payload: { message }
});


