import { all, fork, put, takeEvery } from "redux-saga/effects";
import { CALCULATE_CALORIES } from "../Actions";

import { calculateCaloriesSuccess, calculateCaloriesError } from "./Action";
import { INIT_STATE } from "./Reducer";

// Calculate calories
function* loadCc({ payload }) {
  if (payload.value) {
    if (payload.value.value == "null") {
      yield put(
        calculateCaloriesSuccess({
          value: INIT_STATE,
        })
      );
    } else {
      yield put(calculateCaloriesSuccess(payload));
    }
  } else {
    yield put(calculateCaloriesError(payload));
  }
}

export function* watchCc() {
  yield takeEvery(CALCULATE_CALORIES, loadCc);
}

export default function* rootSaga() {
  yield all([fork(watchCc)]);
}
