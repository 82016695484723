import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routesArray from "./allRoutes";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader.js";
import { useDispatch, useSelector } from "react-redux";
import { getCuurentUser, userDetails } from "../Store/User/Action";

const RoutesIndex = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(({ user }) => user);
  const token = localStorage.getItem("token");

  useEffect(() => {
    token && dispatch(getCuurentUser());
  }, []);


  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Header user={currentUser?.currentUser} />
        <Routes>
          {routesArray.map((route, ky) => (
            <Route key={ky} {...route} />
          ))}
        </Routes>
        <Footer />
      </Router>
    </Suspense>
  );
};

export default RoutesIndex;
