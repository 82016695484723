import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { baseName } from "../../config/ApiConstants";
import { AUTHPATHS, PATHS } from "../../constants/Paths";
import { alrtTypes } from "../../constants/Utils";
import {
  CREATE_USER,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOGOUT,
} from "../Actions";
import { setMessage } from "../AlertMessage/Action";

import {
  createUserSuccess,
  createUserError,
  loginSuccess,
  loginError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  logoutSuccess,
} from "./Action";
import {
  createUserAsync,
  forgotPasswordAsync,
  loginAsync,
  resetPasswordAsync,
} from "./Apis";
import i18n from "../../i18n/config";

// SignUp
function* createUser({ payload }) {
  const response = yield createUserAsync(payload);
  const { data } = response;
  try {
    if (data?.data) {
      yield put(createUserSuccess(data.data));
      localStorage.setItem("email", data.data.email);
      localStorage.setItem("userId", data.data.user_id);
      localStorage.setItem("token", data.data.jwt_token);
      localStorage.setItem("refreshToken", data.data.refresh_token);
      yield put(
        setMessage({
          toastMessage: i18n.t("apiResponse.regSuccess"),
          code: alrtTypes.SUCCESS,
        })
      );
    } else {
      yield put(createUserError(data.error));
      yield put(
        setMessage({
          toastMessage:
            data.error === "ARE"
              ? i18n.t("apiResponse.userExist")
              : i18n.t("apiResponse.someErr"),
          code: alrtTypes.WARNING,
        })
      );
    }
  } catch (err) {
    yield put(createUserError(data.error));
    yield put(
      setMessage({
        toastMessage:
          data.error === "ARE"
            ? i18n.t("apiResponse.userExist")
            : i18n.t("apiResponse.someErr"),
        code: alrtTypes.WARNING,
      })
    );
  }
}

export function* watchCreateUser() {
  yield takeEvery(CREATE_USER, createUser);
}

// Login
function* login({ payload }) {
  const response = yield loginAsync(payload.user);

  try {
    if (response && response?.data?.data) {
      const { data } = response;
      localStorage.setItem("email", data.data.email);
      localStorage.setItem("userId", data.data.user_id);
      localStorage.setItem("token", data.data.jwt_token);
      localStorage.setItem("mobile_no", data.data.mobile_no);
      localStorage.setItem("refreshToken", data.data.refresh_token);

      yield put(loginSuccess(data.data));
      yield put(
        setMessage({
          toastMessage: i18n.t("apiResponse.logSuccess"),
          code: alrtTypes.SUCCESS,
        })
      );
      // payload.navigate(baseName + "dashboard");
    } else {
      yield put(loginError(response?.data.error));
      yield put(
        setMessage({
          toastMessage: response?.data
            ? i18n.t("apiResponse.wrongCred")
            : i18n.t("apiResponse.serverErr"),
          code: alrtTypes.WARNING,
        })
      );
    }
  } catch (error) {
    yield put(loginError(error));
    yield put(
      setMessage({
        toastMessage: error,
        code: alrtTypes.WARNING,
      })
    );
  }
}

export function* watchLogin() {
  yield takeEvery(LOGIN, login);
}

// Forgot Password
function* forgotPassword({ payload }) {
  const response = yield forgotPasswordAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(forgotPasswordSuccess(data.data));
      yield put(
        setMessage({
          toastMessage: data.data,
          code: alrtTypes.SUCCESS,
        })
      );
    } else {
      yield put(forgotPasswordError(data.error));
      yield put(
        setMessage({
          toastMessage: i18n.t("apiResponse.someErr"),
          code: alrtTypes.WARNING,
        })
      );
    }
  } catch (err) {
    yield put(forgotPasswordError(err));
    yield put(
      setMessage({
        toastMessage: i18n.t("apiResponse.someErr"),
        code: alrtTypes.WARNING,
      })
    );
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

// Reset Password
function* resetPassword({ payload }) {
  const response = yield resetPasswordAsync(payload);
  const { data } = response;
  try {
    if (data.error === "SFD") {
      yield put(resetPasswordSuccess(data.data));
      yield put(
        setMessage({
          toastMessage: i18n.t("apiResponse.pwdChange"),
          code: alrtTypes.SUCCESS,
        })
      );
      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 1000);
    } else {
      yield put(resetPasswordError());
      yield put(
        setMessage({
          toastMessage: getErrorMessage(data.error),
          code: alrtTypes.WARNING,
        })
      );
      // setTimeout(() => {
      //   window.location.reload(false);
      // }, 1000);
    }
  } catch (err) {
    yield put(resetPasswordError(err));
    yield put(
      setMessage({
        toastMessage: getErrorMessage(data.error),
        code: alrtTypes.WARNING,
      })
    );
  }
}

// Mapping for API response error codes for reset password
const getErrorMessage = (error) => {
  switch (error) {
    case "NRF":
      return i18n.t("apiResponse.noUser");
    case "PIM":
      return i18n.t("apiResponse.resetDone");
    case "PNM":
      return i18n.t("apiResponse.pwdMatch");
    default:
      return i18n.t("apiResponse.someErr");
  }
};

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

// Logout
function* logout({ payload }) {
  // payload(baseName)
  const lang_id = localStorage.getItem("lang_id"),
    lang = localStorage.getItem("lang");
  localStorage.clear();

  localStorage.setItem("lang", lang);
  localStorage.setItem("lang_id", lang_id);
  yield put(logoutSuccess());
  window.location.reload(false);
}

export function* watchLogout() {
  yield takeEvery(LOGOUT, logout);
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateUser),
    fork(watchLogin),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchLogout),
  ]);
}
