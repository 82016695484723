export const signup_initialValues = {
  firstname: "",
  lastname: "",
  mobile_no: "",
  signup_email: "",
  password: "",
  device_name: "",
  device_token: "",
  device_id: "",
};

export const login_initialValues = {
  login_email: "",
  password: "",
};

export const forgotPassword_initialValues = {
  email: "",
};

export const resetPassword_initialValues = {
  password: "",
  confirmPassword: "",
};

export const changePassword_initialValues = {
  old_password: "",
  new_password: "",
};

export const edit_personalInfo_initialValues = {
  first_name: "",
  last_name: "",
  mobile_no: "965",
  email: "",
};

export const add_edit_address_initailValues = {
  deliveryMethod: "",
  deliveryTime: "",
  governate: "",
  area: "",
  block: "",
  house_no: "",
  street: "",
  avenue: "",
  directions: "",
  floor_no: "",
};

export const editFoodPrefernces_initialValues = {
  allergies: "",
  dislikes: "",
};

export const deliveryDetails_initialValues = {
  startDate: "",
  deliveryMethod: "",
  deliveryTime: "",
  governate: "",
  area: "",
  block: "",
  house_no: "",
  street: "",
  avenue: "",
  directions: "",
  floor_no: "",
  address_type: "",
};

export const deliveryDetails_without_address_initialValues = {
  startDate: "",
  deliveryMethod: "",
  deliveryTime: "",
};
