import {
    LOAD_PLANS,
    LOAD_PLANS_SUCCESS,
    LOAD_PLANS_ERROR,
} from '../Actions';

// Plans
export const loadPlans = (user) => ({
    type: LOAD_PLANS,
    payload: user
});
export const loadPlansSuccess = (user) => ({
    type: LOAD_PLANS_SUCCESS,
    payload: user
});
export const loadPlansError = (message) => ({
    type: LOAD_PLANS_ERROR,
    payload: { message }
});
