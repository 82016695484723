import React from 'react'
import { Modal } from 'react-bootstrap';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import SignUp from './Signup';

const CommonAuthModal = ({ show, handleClose, type, setComponentType }) => {
    return (
        <Modal size="lg" show={show} onHide={handleClose} dialogClassName="modal-authentication" centered>
            {type == "login" ? <Login handleClose={handleClose} setComponentType={setComponentType} /> : null}
            {type === "signup" ? <SignUp handleClose={handleClose} setComponentType={setComponentType} /> : null}
            {type === 'frgtPswrd' ? <ForgotPassword handleClose={handleClose} setComponentType={setComponentType} /> : null}
        </Modal>
    )
}

export default CommonAuthModal