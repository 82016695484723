import axios from "axios";
import { BASE_URL } from "../../config/ApiConstants";
import { activities, roundDownToNearest10 } from "../../constants/Utils";

// User
export const loadUserDetailsAsync = async (authUser) => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  if (authUser) {
    let formData3 = new FormData();
    formData3.append("lang_id", localStorage.getItem("lang_id") || 1);
    formData3.append("jwt", token);
    formData3.append("user_id", userId);

    try {
      const apis = [
        axios.post(`${BASE_URL}/get_allergies`, formData3),
        axios.post(`${BASE_URL}/v2/get_dislikes`, formData3),
        axios.post(`${BASE_URL}/get_user_physical_details`, formData3),
        axios.post(`${BASE_URL}/get_all_allergies`, formData3),
        axios.post(`${BASE_URL}/v2/get_all_ingredients`, formData3),
        axios.post(`${BASE_URL}/useraddresslist`, formData3),
        axios.post(`${BASE_URL}/get_user_weight_history`, formData3),
      ];
      const responses = await Promise.all(apis);
      return responses;
    } catch (error) {
      return error("Something went wrong");
    }
  } else {
    return false;
  }
};

// Get user address only
export const getUserAdressOnlyAsync = async () => {
  const token = localStorage.getItem("token");

  let formData = new FormData();
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("jwt", token);
  formData.append("user_id", localStorage.getItem("userId"));
  try {
    const data = await axios.post(`${BASE_URL}/useraddresslist`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Update personal info
export const updatePersonalInfoAsync = async (data) => {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  let formData = new FormData();
  if (data?.type === "epi") {
    formData.append("lang_id", 1);
    formData.append("user_id", localStorage.getItem("userId"));
    formData.append("email", data.email);
    formData.append("f_name", data.first_name);
    formData.append("l_name", data.last_name);
    formData.append("mobile", data.mobile_no);
    formData.append("jwt", token);
    try {
      const data = axios.post(`${BASE_URL}/update_personal_details`, formData);
      return data;
    } catch (error) {
      return error("Something went wrong");
    }
  } else {
    const dislikes = data.dislikes?.map((elem) => {return {"id": elem.value, type: elem.group ? 'dislike_category' : 'ingredient'}});
    const allergies = data.allergies?.map((elem) => elem.value);
    let formData1 = new FormData();
    let formData2 = new FormData();
    formData1.append(
      "dislikes",
      data.dislikes ? JSON.stringify(dislikes) : null
    );
    formData1.append("user_id", userId);
    formData1.append("jwt", token);

    formData2.append(
      "allergies",
      data.allergies ? JSON.stringify(allergies) : null
    );
    formData2.append("user_id", userId);
    formData2.append("jwt", token);
    try {
      const apis = [
        axios.post(`${BASE_URL}/add_user_allergies`, formData2),
        axios.post(`${BASE_URL}/v2/set_user_dislikes`, formData1),
      ];
      const data = Promise.allSettled(apis);
      return data;
    } catch (error) {
      return error("Something went wrong");
    }
  }
};

// Update Calorie
export const updateCalorieAsync = async (calorie) => {
  let formData = new FormData();
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("jwt", localStorage.getItem("token"));
  formData.append("target_cal", calorie);

  try {
    const data = await axios.post(`${BASE_URL}/update_user_calorie`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Add address
export const addAddressAsync = async (address) => {
  let formData = new FormData();
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("area_id", address?.area_id);
  formData.append("address_type", address?.address_type);
  formData.append("block", address?.block);
  formData.append("directions", address?.directions);
  formData.append("street", address?.street);
  formData.append("avenue", address?.avenue);
  formData.append("flat_no", address?.flat_no);
  formData.append("house_number", address?.house_number);
  formData.append("landmark", address?.landmark);
  formData.append("delivery_method_id", address?.delivery_method_id);
  formData.append("delivery_time_id", address?.delivery_time_id);
  formData.append("jwt", localStorage.getItem("token"));
  if (address?.isEditable) {
    formData.append("main_address_id", address?.address_id);
  }
  try {
    const data = await axios.post(`${BASE_URL}/addaddress`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Set Primary Address
export const setPrimaryAddressAsync = async (address) => {
  let formData = new FormData();
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("jwt", localStorage.getItem("token"));
  formData.append("address_id", address?.address_id);
  formData.append("is_primary", address?.is_primary);

  try {
    const data = await axios.post(`${BASE_URL}/set_primary_address`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// save Physical Data
export const savePhysicalDataAsync = async (data) => {
  let formData = new FormData();
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("jwt", localStorage.getItem("token"));
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("fitness_goal", data?.goal);
  formData.append("pre_weight", Math.round(data?.pWeight));
  formData.append("tar_weight", Math.round(data?.tWeight));
  formData.append("height", Math.round(data?.height));
  formData.append("activity", activities[data?.activityIndex].content);
  formData.append("age", data?.age);
  formData.append("gender", data?.gender);
  formData.append("target_cal", roundDownToNearest10(data?.result));
  formData.append("weight_unit", data?.unitPresentWeight);
  formData.append("height_unit", data?.unitHeight);

  // localStorage.getItem('ls_langauge_id') ||
  try {
    const data = await axios.post(
      `${BASE_URL}/save_user_physical_details`,
      formData
    );
    return data;
  } catch (error) {
    return error;
  }
};

// get Current user Data
export const getCurrentUSerAsync = async (data) => {
  let formData = new FormData();
  formData.append("email", localStorage.getItem("email"));
  formData.append("jwt", localStorage.getItem("token"));
  try {
    const data = await axios.post(`${BASE_URL}/get_user_data`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

// Add todat user weight
export const addTodayUserWeightAsync = async (weight) => {
  let formData = new FormData();
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("jwt", localStorage.getItem("token"));
  formData.append("jwt", localStorage.getItem("token"));
  formData.append("weight", weight);
  try {
    const data = await axios.post(`${BASE_URL}/add_user_weight`, formData);
    return data;
    // return data;
  } catch (error) {
    return error;
  }
};

export const mealFavDontShowAsync = async (status) => {
  let formData = new FormData();
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("jwt", localStorage.getItem("token"));
  formData.append("status", status);
  try {
    const data = await axios.post(`${BASE_URL}/hide_fav_popup`, formData);
    return data;
    // return { data: { data: true } };
  } catch (error) {
    return error;
  }
};
// updateDeliveryTypeAsync
export const updateDeliveryTypeAsync = async (delivery) => {
  let formData = new FormData();
  formData.append("address_id", delivery?.address_id);
  formData.append("jwt", localStorage.getItem("token"));
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("delivery_method_id", delivery?.delivery_method_id);
  formData.append("delivery_time_id", delivery?.delivery_time_id);
  try {
    const data = await axios.post(
      `${BASE_URL}/set_delivery_method_and_time`,
      formData
    );
    return data;
  } catch (error) {
    return error;
  }
};
