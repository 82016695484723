import axios from "axios";
import { BASE_URL } from "../../config/ApiConstants";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("es_token")}`,
    "Content-Type": "multipart/form-data",
  },
};

// Plans
export const loadPlansAsync = async () => {
  let formData = new FormData();
  formData.append("type", "advertise");
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);

  try {
    const apis = [
      axios.post(`${BASE_URL}/packagetypelist`, formData),
      axios.post(`${BASE_URL}/home_meallist`, formData),
    ];
    const responses = await Promise.all(apis);
    return responses;
  } catch (error) {
    return error;
  }
};
