import {
    CALORIE_PLANS,
    CALORIE_PLANS_SUCCESS,
    CALORIE_PLANS_ERROR,
} from '../Actions';

// Plans
export const caloriePlanDetails = (planQuery) => ({
    type: CALORIE_PLANS,
    payload: planQuery
});
export const caloriePlanDetailsSuccess = (user) => ({
    type: CALORIE_PLANS_SUCCESS,
    payload: user
});
export const caloriePlanDetailsError = (message) => ({
    type: CALORIE_PLANS_ERROR,
    payload: { message }
});
