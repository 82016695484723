import { RESET_MESSAGE, SET_MESSAGE } from '../Actions';

const data = {
    toastMessage: "",
    code: "",
    heading: ""
};
export default (state = data, action) => {
    switch (action.type) {
        case RESET_MESSAGE:
            return { toastMessage: "", message: "", body: "" };
        case SET_MESSAGE:
            return { ...action.payload };
        default:
            return { ...state };
    }
}

