import moment from "moment";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import { PATHS } from "../../constants/Paths";
import { alrtTypes } from "../../constants/Utils";
import {
  GET_END_DATE,
  LOAD_SUBSCRIPTION,
  MAKE_PROMO_NULL,
  PROMOCODE_IS_VALID,
  PURCHASE_PACKAGE,
} from "../Actions";
import { setMessage } from "../AlertMessage/Action";

import {
  loadSubscriptionSuccess,
  loadSubscriptionError,
  promocodeIsValidSuccess,
  promocodeIsValidError,
  purchasePackageSuccess,
  purchasePackageError,
  getEndDateSuccess,
  getEndDateError,
  makePromoNullSuccess,
  makePromoNullError,
} from "./Action";

import {
  getEndDateAsync,
  getexSubEndDateAsync,
  loadPromocodeIsValidAsync,
  loadpurchasePackageAsync,
  loadSubscriptionAsync,
} from "./Apis";

import i18n from "../../i18n/config";

// Plan Details
function* loadSubscription({ payload }) {
  const response = yield loadSubscriptionAsync(payload);
  // const { data } = response;
  const {
    data: { data: deliveryTime },
  } = response[0];
  const {
    data: { data: DeliveryMethod },
  } = response[1];
  const {
    data: { data: useraddresslist },
  } = response[2];
  const {
    data: { data: arealist },
  } = response[3];
  try {
    if (response?.length) {
      yield put(
        loadSubscriptionSuccess({
          deliveryTime: deliveryTime,
          DeliveryMethod: DeliveryMethod,
          useraddresslist: useraddresslist,
          arealist: arealist,
        })
      );
    } else {
      yield put(
        loadSubscriptionError(new Error(i18n.t("apiResponse.tryAgain")))
      );
      yield put(
        setMessage({
          toastMessage: i18n.t("apiResponse.noRecord"),
          code: alrtTypes.WARNING,
        })
      );
    }
  } catch (err) {
    yield put(loadSubscriptionError(err));
  }
}

export function* watchLoadSubscription() {
  yield takeEvery(LOAD_SUBSCRIPTION, loadSubscription);
}

// Promocode
function* promocodeIsValid({ payload }) {
  const response = yield loadPromocodeIsValidAsync(payload.promocode);
  const { data } = response;
  try {
    if (data.data) {
      yield put(promocodeIsValidSuccess(data.data));
      if (
        data.data?.discount_type == "amount" &&
        data.data?.minimum_amount > payload?.p_price
      ) {
        yield put(
          setMessage({
            toastMessage: i18n.t("apiResponse.minimumAmount"),
            code: alrtTypes.WARNING,
          })
        );
      } else {
        yield put(
          setMessage({
            toastMessage: i18n.t("apiResponse.couponApply"),
            code: alrtTypes.SUCCESS,
          })
        );
      }
    } else {
      yield put(promocodeIsValidError(data.error));
      yield put(
        setMessage({
          toastMessage: i18n.t("apiResponse.couponInv"),
          code: alrtTypes.WARNING,
        })
      );
    }
  } catch (err) {
    yield put(promocodeIsValidError(data?.error));
    yield put(
      setMessage({
        toastMessage: i18n.t("apiResponse.couponInv"),
        code: alrtTypes.WARNING,
      })
    );
  }
}

export function* watchLoadPromocodeIsValid() {
  yield takeEvery(PROMOCODE_IS_VALID, promocodeIsValid);
}

// Purchase
function* purchasePackage({ payload }) {
  const response = yield loadpurchasePackageAsync(payload._data);
  const { data } = response;
  let date = moment(new Date()).format("DD-MM-yyyy");
  try {
    if (data.data) {
      yield put(purchasePackageSuccess(data.data));
      if (data?.data?.totAmount && parseFloat(data.data.totAmount).toFixed(3) == "0.000")
        payload.navigate(
          `${PATHS.PAYMENT_SUCCESS}?Result=PAID&date=${date}&package_name=${data?.data?.productnames}&Amount=${data?.data?.totAmount}&purchased_from=web`
        );
      else if (data?.data?.payment_url) {
        window.location.href = data?.data?.payment_url;
      }
    } else {
      yield put(purchasePackageError(data?.error_msg));
      yield put(
        setMessage({
          toastMessage: data?.error_msg,
          code: alrtTypes.WARNING,
        })
      );
    }
  } catch (err) {
    yield put(purchasePackageError(data.error_msg));
    yield put(
      setMessage({
        toastMessage: data?.error_msg,
        code: alrtTypes.WARNING,
      })
    );
  }
}

export function* watchLoadPurchasePackage() {
  yield takeEvery(PURCHASE_PACKAGE, purchasePackage);
}

// Get end date
function* get_end_date({ payload }) {
  const response = yield getEndDateAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(getEndDateSuccess(data.data));
    } else {
      yield put(getEndDateError(data?.error_msg));
    }
  } catch (err) {
    yield put(getEndDateError(data?.error_msg));
  }
}

export function* watchGetEndDate() {
  yield takeEvery(GET_END_DATE, get_end_date);
}

// Get end date
function* watchMake_promo_null({ payload }) {
  const response = yield getexSubEndDateAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(makePromoNullSuccess(data.data?.end_date));
    } else {
      yield put(makePromoNullError(data?.error_msg));
    }
  } catch (err) {
    yield put(makePromoNullError(data.error_msg));
  }
}

export function* watchMakePromonull() {
  yield takeEvery(MAKE_PROMO_NULL, watchMake_promo_null);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadSubscription),
    fork(watchLoadPromocodeIsValid),
    fork(watchLoadPurchasePackage),
    fork(watchGetEndDate),
    fork(watchMakePromonull),
  ]);
}
