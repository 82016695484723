import React from "react";
import { Field, getIn } from "formik";
import { Button, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import "./style.scss";

function getStyles(errors, fieldName) {
  if (getIn(errors, fieldName)) {
    return {
      boxShadow: "0 0 0 1px #ef2c2c",
    };
  }
}

export const Input = (props) => {
  const {
    name,
    type,
    onChange,
    value,
    errors,
    touched,
    placeholder,
    floatingLabel,
    ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <div className="form-group">
      <Field
        id={name}
        type={type}
        name={name}
        placeholder={!floatingLabel?.length && placeholder}
        {...rest}
        style={getStyles(errors, name)}
      />

      {floatingLabel && (
        <span className={`floating_label ${value ? "label-active" : ""}`}>
          {placeholder}
        </span>
      )}

      <div className="error">{t(errors?.[name])}</div>
    </div>
  );
};

export const PassswordInputWithEyeIcon = (props) => {
  const {
    name,
    type,
    onChange,
    value,
    errors,
    touched,
    placeholder,
    inputType,
    onClick,
    ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <div className="form-group">
      <InputGroup>
        <Field
          id={name}
          type={type}
          name={name}
          placeholder={placeholder}
          {...rest}
          style={getStyles(errors, name)}
        />

        <Button
          type="button"
          variant="link"
          onClick={onClick}
          className={`icon-eyes-${inputType}`}
        ></Button>
      </InputGroup>

      <div className="error">{t(errors?.[name])}</div>
    </div>
  );
};

export const Select = (props) => {
  const {
    label,
    name,
    onChange,
    disabled,
    selectedValue1,
    options,
    errors,
    floatingLabel,
    placeholder,
    ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <div className="form-group select-contents">
      <Field
        as="select"
        id={name}
        name={name}
        disabled={disabled}
        {...rest}
        style={errors && getStyles(errors, name)}
        onChange={onChange}
        placeholder={placeholder}
      >
        <option
          defaultValue
          placeholder={placeholder ? placeholder : "select"}
          value=""
        >
          {placeholder ? placeholder : "Select value"}
        </option>

        {Object.keys(options)?.length > 0 &&
          options?.map((option, ind) => {
            return (
              <option
                className="option-dummy"
                key={ind}
                value={option?.value === placeholder ? "" : option?.value}
              >
                {option?.key}
              </option>
            );
          })}
      </Field>

      <div className="error">{t(errors?.[name])}</div>
    </div>
  );
};

export const PhoneNumberInput = (props) => {
  const {
    name,
    type,
    onChange,
    value,
    errors,
    touched,
    placeholder,
    floatingLabel,
    className,
    ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <div
      className={`form-group ${className || ""} ${
        errors?.[name] ? "apply-error" : ""
      } ${
        floatingLabel
          ? `floating-label floating-label${
              value?.length <= 4 ? "--empty" : "--active"
            }`
          : ""
      }`}
      onClick={(_) => document.getElementById(name).focus()}
    >
      <PhoneInput
        inputClass={`${errors?.[name] ? "num-error" : ""} `}
        country={"kw"}
        value={value}
        countryCodeEditable={false}
        onlyCountries={["kw"]}
        onChange={(phone) => onChange(`+${phone}`)}
        defaultMask=".... .... ...."
        specialLabel={placeholder}
        inputProps={{
          name: name,
          required: true,
          maxLength: 14,
          placeholder: placeholder,
          id: name,
          ...rest,
        }}
        disableDropdown
        copyNumbersOnly
      />

      <div className="error">{t(errors?.[name])}</div>
    </div>
  );
};
