import {
    LOAD_MEAL_LIST,
    LOAD_MEAL_LIST_SUCCESS,
    LOAD_MEAL_LIST_ERROR,
    LOAD_MY_MEAL,
    LOAD_MY_MEAL_SUCCESS,
    LOAD_MY_MEAL_ERROR,
    ADD_MEAL,
    ADD_MEAL_SUCCESS,
    ADD_MEAL_ERROR,
    LOAD_MEAL_LIST_SINGLE_SHORT_SUCCESS,
    LOAD_MEAL_LIST_SINGLE_SHORT,
    LOAD_MEAL_LIST_SINGLE_SHORT_ERROR,
    RESET_MEAL,
    RESET_MEAL_SUCCESS,
    FAV_MEAL,
    FAV_MEAL_SUCCESS,
    FAV_MEAL_ERROR,
} from '../Actions';


const INIT_STATE = {
    mealList: [],
    myMeal: [],
    allMealList: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_MEAL_LIST:
            return { ...state, loading: true, error: '' };
        case LOAD_MEAL_LIST_SUCCESS:
            return {
                ...state, loading: false, mealList: { ...state.mealList, ...action.payload },
                error: ''
            };
        case LOAD_MEAL_LIST_ERROR:
            return { ...state, loading: false, mealList: [], error: action.payload.message };

        case LOAD_MY_MEAL:
            return { ...state, loading: true, error: '' };
        case LOAD_MY_MEAL_SUCCESS:
            return { ...state, loading: false, myMeal: action.payload, error: '' };
        case LOAD_MY_MEAL_ERROR:
            return { ...state, loading: false, myMeal: [], error: action.payload.message };

        case ADD_MEAL:
            return { ...state, loading: true, error: '' };
        case ADD_MEAL_SUCCESS:
            return { ...state, loading: false, error: '' };
        case ADD_MEAL_ERROR:
            return { ...state, loading: false, error: action.payload.message };

        case LOAD_MEAL_LIST_SINGLE_SHORT:
            return { ...state, loading: true, error: '' };
        case LOAD_MEAL_LIST_SINGLE_SHORT_SUCCESS:
            return { ...state, loading: false, allMealList: action?.payload, error: '' };
        case LOAD_MEAL_LIST_SINGLE_SHORT_ERROR:
            return { ...state, loading: false, allMealList: [], error: action.payload.message };


        case FAV_MEAL:
            return { ...state, error: '' };
        case FAV_MEAL_SUCCESS:
            const index = state.allMealList?.findIndex((elem) => elem?.main_meal_id == action?.payload?.meal_id);
            if (index !== -1) {
                state.allMealList[index].favourite = action?.payload?.status == 0 ? true : false;
            }
            return { ...state, loading: false, error: '' };
        case FAV_MEAL_ERROR:
            return { ...state, loading: false, error: action.payload.message };

        // Reset user
        case RESET_MEAL_SUCCESS:
            state.allMealList = [];
            state.mealList = []
            state.myMeal = []
            return { ...state, loading: false, currentUser: null, error: '' };
        default: return { ...state };
    }
}