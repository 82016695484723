import {
    LOAD_PLANS,
    LOAD_PLANS_SUCCESS,
    LOAD_PLANS_ERROR,
} from '../Actions';


const INIT_STATE = {
    home: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_PLANS:
            return { ...state, loading: true, error: '' };
        case LOAD_PLANS_SUCCESS:
            return { ...state, loading: false, plans: action.payload, error: '' };
        case LOAD_PLANS_ERROR:
            return { ...state, loading: false, plans: null, error: action.payload.message };
        default: return { ...state };

    }
}