import axios from "axios";
import moment from "moment";
import { BASE_URL } from "../../config/ApiConstants";

// MealCal
export const loadMealCalAsync = async (payload) => {
  let formData = new FormData();
  formData.append("order_id", payload);
  formData.append("jwt", localStorage.getItem("token"));
  try {
    const data = await axios.post(`${BASE_URL}/getOrderDates`, formData);
    return data;
  } catch (error) {
    return error;
  }
};

const changeDateFormat = (datesArray) => {
  return datesArray?.map((d) => {
    return moment(d).format("YYYY-MM-DD");
  });
};

// date suspend
export const dateSuspendAsync = async (data) => {
  let formData = new FormData();
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("jwt", localStorage.getItem("token"));
  formData.append("order_id", data?.order_id);
  formData.append("sub_package_id", data?.sub_package_id);
  formData.append(
    "freeze_dates",
    JSON.stringify(changeDateFormat(data?.freeze_dates))
  );
  try {
    const data = await axios.post(
      `${BASE_URL}/suspendplanwithmultipledates`,
      formData
    );
    return data;
  } catch (error) {
    return error;
  }
};

// date unSuspend
export const dateUnSuspendAsync = async (data) => {
  let formData = new FormData();
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("jwt", localStorage.getItem("token"));
  formData.append("order_id", data?.order_id);
  formData.append("sub_package_id", data?.sub_package_id);
  formData.append(
    "unfreeze_dates",
    JSON.stringify(changeDateFormat(data?.unfreeze_dates))
  );
  try {
    const data = await axios.post(
      `${BASE_URL}/unfreezeplanwithmultipledates`,
      formData
    );
    return data;
  } catch (error) {
    return error;
  }
};
