import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { alrtTypes } from '../../constants/Utils';
import { LOAD_DASHBOARD, LOAD_MY_MEALS } from '../Actions';
import { setMessage } from '../AlertMessage/Action';

import {
    loadDashboardSuccess,
    loadDashboardError,
    loadMyMealsError,
    loadMyMealsSuccess
} from './Action';
import { loadDashboardAsync, loadMyMealsAsync } from './Apis';



// Dashboard
function* loadDashboard({ payload }) {
    const response = yield loadDashboardAsync(payload);
    const { data } = response;
    try {
        if (data.data) {
            yield put(loadDashboardSuccess(data.data));
        }

        else {
            yield put(loadDashboardError(data.error));
        }
    }
    catch (err) {
        yield put(loadDashboardError(data?.error));
    }
}

export function* watchLoadDashboard() {
    yield takeEvery(LOAD_DASHBOARD, loadDashboard);
}

// My meals
function* loadMyMeals({ payload }) {
    const response = yield loadMyMealsAsync(payload);
    const { data } = response;
    try {
        if (data.data) {
            yield put(loadMyMealsSuccess(data.data));

        }

        else {
            yield put(loadMyMealsError(data.error));
        }
    }
    catch (err) {
        yield put(loadMyMealsError(data?.error));

    }
}

export function* watchLoadMyMeals() {
    yield takeEvery(LOAD_MY_MEALS, loadMyMeals);
}

export default function* rootSaga() {
    yield all([
        fork(watchLoadMyMeals),
        fork(watchLoadDashboard)

    ]);
}