import {
    LOAD_SUBSCRIPTION,
    LOAD_SUBSCRIPTION_SUCCESS,
    LOAD_SUBSCRIPTION_ERROR,
    PROMOCODE_IS_VALID,
    PROMOCODE_IS_VALID_SUCCESS,
    PROMOCODE_IS_VALID_ERROR,
    PURCHASE_PACKAGE,
    PURCHASE_PACKAGE_SUCCESS,
    PURCHASE_PACKAGE_ERROR,
    GET_END_DATE,
    GET_END_DATE_SUCCESS,
    GET_END_DATE_ERROR,
    MAKE_PROMO_NULL,
    MAKE_PROMO_NULL_SUCCESS,
    MAKE_PROMO_NULL_ERROR,

} from '../Actions';


const INIT_STATE = {
    subscription: [],
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOAD_SUBSCRIPTION:
            return { ...state, loading: true, error: '' };
        case LOAD_SUBSCRIPTION_SUCCESS:
            return { ...state, loading: false, subscription: action.payload, error: '' };
        case LOAD_SUBSCRIPTION_ERROR:
            return { ...state, loading: false, subscription: null, error: action.payload.message };

        case PROMOCODE_IS_VALID:
            return { ...state, loading: true, error: '' };
        case PROMOCODE_IS_VALID_SUCCESS:
            return { ...state, loading: false, promocode: action.payload, error: '' };
        case PROMOCODE_IS_VALID_ERROR:
            return { ...state, loading: false, promocode: null, error: action.payload.message };

        case PURCHASE_PACKAGE:
            return { ...state, loading: true, error: '' };
        case PURCHASE_PACKAGE_SUCCESS:
            return { ...state, loading: false, package: action.payload, error: '' };
        case PURCHASE_PACKAGE_ERROR:
            return { ...state, loading: false, package: null, error: action.payload.message };

        case GET_END_DATE:
            return { ...state, loading: true, error: '' };
        case GET_END_DATE_SUCCESS:
            state.promocode = null;
            return { ...state, loading: false, endDate: action.payload, error: '' };
        case GET_END_DATE_ERROR:
            return { ...state, loading: false, endDate: null, error: action.payload.message };

        case MAKE_PROMO_NULL:
            return { ...state, error: '' };
        case MAKE_PROMO_NULL_SUCCESS:
            state.promocode = null;
            return { ...state, ex_sub_endDate: action.payload, error: '' };
        case MAKE_PROMO_NULL_ERROR:
            return { ...state, loading: false, error: action.payload.message };
        default: return { ...state };

    }
}