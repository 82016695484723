import {
  LOAD_MEAL_CAL,
  LOAD_MEAL_CAL_SUCCESS,
  LOAD_MEAL_CAL_ERROR,
  DATE_SUSPEND,
  DATE_SUSPEND_SUCCESS,
  DATE_SUSPEND_ERROR,
  DATE_UN_SUSPEND,
  DATE_UN_SUSPEND_SUCCESS,
  DATE_UN_SUSPEND_ERROR,
} from "../Actions";

const INIT_STATE = {
  mealDates: [],
  dateSuspend: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_MEAL_CAL:
      return { ...state, loading: true, error: "" };
    case LOAD_MEAL_CAL_SUCCESS:
      return { ...state, loading: false, mealDates: action.payload, error: "" };
    case LOAD_MEAL_CAL_ERROR:
      return {
        ...state,
        loading: false,
        mealDates: [],
        error: action.payload.message,
      };

    case DATE_SUSPEND:
      return {
        ...state,
        loading: true,
        dateSuspend: true,
        error: "",
      };
    case DATE_SUSPEND_SUCCESS:
      return { ...state, loading: false, dateSuspend: false, error: "" };
    case DATE_SUSPEND_ERROR:
      return {
        ...state,
        loading: false,
        dateSuspend: false,
        error: action.payload.message,
      };

    case DATE_UN_SUSPEND:
      return {
        ...state,
        loading: true,
        dateUnSuspend: true,
        dateUnSuspend: 1,
        error: "",
      };
    case DATE_UN_SUSPEND_SUCCESS:
      return { ...state, loading: false, dateUnSuspend: false, error: "" };
    case DATE_UN_SUSPEND_ERROR:
      return {
        ...state,
        loading: false,
        dateUnSuspend: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
