import {
    PLAN_DETAILS,
    PLAN_DETAILS_SUCCESS,
    PLAN_DETAILS_ERROR,
} from '../Actions';

// Plans
export const planDetails = (plan_id) => ({
    type: PLAN_DETAILS,
    payload: plan_id
});
export const planDetailsSuccess = (user) => ({
    type: PLAN_DETAILS_SUCCESS,
    payload: user
});
export const planDetailsError = (message) => ({
    type: PLAN_DETAILS_ERROR,
    payload: { message }
});
