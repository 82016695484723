import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { CALORIE_PLANS } from '../Actions';

import {
    caloriePlanDetailsSuccess,
    caloriePlanDetailsError
} from './Action';
import { loadCaloriePlanDetailsAsync } from './Apis';



// Plan Details
function* loadCaloriePlanDetails({ payload }) {
    const response = yield loadCaloriePlanDetailsAsync(payload);
    const { data } = response;
    try {
        if (data.data) {
            yield put(caloriePlanDetailsSuccess(data.data));
        }

        else {
            yield put(caloriePlanDetailsError(data.error));
        }
    }
    catch (err) {
        yield put(caloriePlanDetailsError(data?.error));
    }
}

export function* watchLoadCaloriePlanDetails() {
    yield takeEvery(CALORIE_PLANS, loadCaloriePlanDetails);
}

export default function* rootSaga() {
    yield all([
        fork(watchLoadCaloriePlanDetails)
    ]);
}