import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
import './style.scss';
const Loader = () => {
    return (
        <div className="loading-wrp">
            <Spinner className="loading" animation="border" variant="secondary" />
        </div>
    )
}

export default Loader