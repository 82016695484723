import {
    LOAD_DASHBOARD,
    LOAD_DASHBOARD_SUCCESS,
    LOAD_DASHBOARD_ERROR,
    LOAD_MY_MEALS,
    LOAD_MY_MEALS_SUCCESS,
    LOAD_MY_MEALS_ERROR,
    RESET_DASHBOARD,
} from '../Actions';


const INIT_STATE = {
    dashboard: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // Load dashboard
        case LOAD_DASHBOARD:
            return { ...state, loading: true, error: '' };
        case LOAD_DASHBOARD_SUCCESS:
            return { ...state, loading: false, dashboard: action.payload, error: '' };
        case LOAD_DASHBOARD_ERROR:
            return { ...state, loading: false, dashboard: null, error: action.payload.message };

        // My meals
        case LOAD_MY_MEALS:
            return { ...state, loading: true, isLoadingMeal: true, error: '' };
        case LOAD_MY_MEALS_SUCCESS:
            return { ...state, loading: false, isLoadingMeal: false, myMeals: action.payload?.[0], error: '' };
        case LOAD_MY_MEALS_ERROR:
            return { ...state, loading: false, isLoadingMeal: false, myMeals: null, error: action.payload.message };

        case RESET_DASHBOARD: 
        
        return INIT_STATE;
        default: return { ...state };
    }
}