import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ForgotPassword from "./ForgotPassword";
import SignUp from "./Signup";
import { Formik, Form } from "formik";

import "./style.scss";
import { login_initialValues } from "../../constants/Formik/FormikInitialValues";
import FormikController from "../../constants/Formik/FormikController";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../Store/Auth/Action";
import { useNavigate } from "react-router-dom";
import { getCuurentUser } from "../../Store/User/Action";
import { baseName } from "../../config/ApiConstants";

import { useTranslation } from "react-i18next";
import { login_validationSchema } from "../../constants/Formik/FormikValidations";

function Login(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(({ authUser }) => authUser);

  userData.forgotPassword = null;

  const [inputType, setInputType] = useState("password");
  const updateInputType = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  const onSubmit = (values) => {
    values.email = values?.login_email;
    dispatch(login(values, navigate));
  };
  const { t } = useTranslation();
  const expression = "login";

  useEffect(() => {
    if (userData?.user && !userData?.loading) {
      props.handleClose();
      dispatch(getCuurentUser());
      if (!localStorage.getItem("toD")) {
        navigate(baseName + "dashboard");
      } else {
        localStorage.removeItem("toD");
      }
    }
  }, [!userData?.loading]);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t(`${expression}.title`)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="txt-grey">{t(`${expression}.desc`)}</p>

        <Formik
          initialValues={login_initialValues}
          validationSchema={login_validationSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
        >
          {({ errors, touched }) => {
            return (
              <Form className="authentication-form signup-form">
                <div className="form-group">
                  <FormikController
                    control="input"
                    type="text"
                    name="login_email"
                    className="form-control"
                    placeholder={t(`${expression}.input1`)}
                    autoFocus={true}
                    errors={touched?.login_email && errors}
                  />

                  <FormikController
                    control="inputwithP"
                    type={inputType}
                    name="password"
                    className="form-control"
                    placeholder={t(`${expression}.input2`)}
                    inputType={inputType}
                    onClick={updateInputType}
                    errors={touched?.password && errors}
                  />
                </div>

                <div className="text-end forgot-password">
                  <Button
                    variant="link"
                    onClick={() => props.setComponentType("frgtPswrd")}
                  >
                    {t(`${expression}.forgot`)}
                  </Button>
                </div>

                <Button variant="primary" className="btn-fill" type="submit">
                  {" "}
                  {t(`signup.login`)}{" "}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>

      <Modal.Footer className="flex-column">
        <div className="mx-0 or-blk">
          <span className="txt-or">{t(`${expression}.or`)}</span>
        </div>

        <div className=" m-0 bottom-blk">
          <p>{t(`${expression}.new`)}</p>

          <Button
            variant="light"
            className="btn-outline"
            onClick={() => props.setComponentType("signup")}
          >
            {t(`${expression}.signup`)}
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
}

export default Login;
