import {
    PLAN_DETAILS,
    PLAN_DETAILS_SUCCESS,
    PLAN_DETAILS_ERROR,
} from '../Actions';


const INIT_STATE = {
    planDetails: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PLAN_DETAILS:
            return { ...state, loading: true, error: '' };
        case PLAN_DETAILS_SUCCESS:
            return { ...state, loading: false, planDetails: action.payload, error: '' };
        case PLAN_DETAILS_ERROR:
            return { ...state, loading: false, planDetails: null, error: action.payload.message };
        default: return { ...state };

    }
}