import {
    CALORIE_PLANS,
    CALORIE_PLANS_SUCCESS,
    CALORIE_PLANS_ERROR,
} from '../Actions';


const INIT_STATE = {
    caloriePlanDetails: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CALORIE_PLANS:
            return { ...state, loading: true, error: '' };
        case CALORIE_PLANS_SUCCESS:
            return { ...state, loading: false, caloriePlanDetails: action.payload, error: '' };
        case CALORIE_PLANS_ERROR:
            return { ...state, loading: false, caloriePlanDetails: null, error: action.payload.message };

        default: return { ...state };

    }
}