import axios from "axios";
import { BASE_URL } from "../../config/ApiConstants";

// Plans
export const loadFaqAsync = async () => {
  let formData = new FormData();
  formData.append("language_id", localStorage.getItem("lang_id") || 1);

  try {
    const data = await axios.post(`${BASE_URL}/faqslist`, formData);
    return data;
  } catch (error) {
    return error;
  }
};
