import { baseName } from "../config/ApiConstants";

export const PATHS = {
  APP_INIT: baseName,
  HOME: baseName,
  REDIRECT: "*",
  FAQ: baseName + "faq",
  RESET_PASSWORD: baseName + "reset-password",
  PASSWORD_CHANGED: baseName + "password-changed",
  TERMS_AND_CONDITIONS: baseName + "terms-conditions",
  CALCULATE_CALORIE: baseName + "calculate-calorie",
  CALCULATE_RESULT: baseName + "result/:calorie",
  CALCULATE_RESULT1: baseName + "result/:isFind/:calorie",
  CALCULATE_RESULT_ONLY: baseName + "result/",
  CALORIE_SELECT: baseName + "find-plan",
  SUBSCRIPTION: baseName + "subscriptions/:plan_id/:sub_id/:days/:cal",
  SUBSCRIPTION2: baseName + "subscriptions/:plan_id/:sub_id/:days",
  SUBSCRIPTION_ONLY: baseName + "subscriptions/",

  PAYMENT_SUCCESS: baseName + "payment-success/",
  PAYMENT_SUCCESS2: baseName + "payment-success/",
  PAYMENT_SUCCESS_ONLY: baseName + "payment-success/",
  PAYMENT_FAIL: baseName + "payment-failure/",
  PAYMENT_FAIL_ONLY: baseName + "payment-failure/",
  AUTH_LAYOUT: "/*",
  AUTH_LAYOUT1: "/",
  // AUTH_LAYOUT: baseName + "/*",
  // AUTH_LAYOUT1: baseName + "/",
  FORGOT_PASSWORD_REDIRECT_LINK: baseName + "reset-password/:token/:time",
  PLAN_DETAILS: baseName + "plan-details/:plan_id",
  PLAN_DETAILS_ONLY: baseName + "plan-details/",
  MENU_REVIEW: baseName + "menu-review",
  PRIVACY_POLICY: baseName + "privacy-policy",
};

export const AUTHPATHS = {
  DASHBOARD: "dashboard",
  EDIT_PROFILE: "edit-profile",
  PROFILE: "profile",
  EXTENDED_SUBSCRIPTION_ONLY: "extended-subscription",
  EXTENDED_SUBSCRIPTION: "extended-subscription/:activePlan",
  PAYMENT_SUBSCRIPTION: "payment-subscription/:activePlan/:days",
  PAYMENT_SUBSCRIPTION_ONLY: "payment-subscription",
  SELECT_MEALS: "select-meals/:plan_id/:order_id",
  SELECT_MEALS1: "select-meals/:plan_id/:order_id/:date",
  SELECT_MEALS_ONLY: "select-meals/",
  MEAL_CAL: "meal-cal/:plan_id/:order_id",
  MEAL_CAL_ONLY: "meal-cal",
};

export const checkLoginPaths = {
  PLAN_DETAILS: "plan-details",
  RESULT: "result",
};
