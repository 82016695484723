import axios from "axios";
import { BASE_URL } from "../../config/ApiConstants";

// Plans
export const loadPlanDetailsAsync = async (plan_id) => {
  let formData = new FormData();
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("package_id", plan_id);

  try {
    const data = await axios.post(`${BASE_URL}/packagelistV2`, formData);
    return data;
  } catch (error) {
    return error;
  }
};
