import { isValidPhoneNumber } from "react-phone-number-input";

import {
  alphaOnly,
  emailPhoneRegex,
  passwordRegex,
  numericOnly,
  specialCharacters,
} from "../Utils";
import * as Yup from "yup";
const plus = "+";

export const signup_validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required("formValidations.fnameReq")
    // .matches(specialCharacters, "formValidations.fnameAlpha")
    .test("is-empty-space", "formValidations.fnameReq", (val) =>
      val?.trim().length ? true : false
    )
    .test(
      "has-special-char",
      "formValidations.fnameAlpha",
      (value) => value && !specialCharacters.test(value)
    ),
  lastname: Yup.string()
    .required("formValidations.lnameReq")
    // .matches(alphaOnly, "formValidations.lnameAlpha")
    .test("is-empty-space", "formValidations.lnameReq", (val) =>
      val?.trim().length ? true : false
    )
    .test(
      "has-special-char",
      "formValidations.lnameAlpha",
      (value) => value && !specialCharacters.test(value)
    ),
  mobile_no: Yup.string()
    .required("formValidations.numReq")
    .test(
      "is-number",
      "formValidations.invNum",
      (value) => value && value?.length === 12
    ),
  signup_email: Yup.string()
    .required("formValidations.emailReq")
    .test("is-email", "formValidations.invEmail", (value) =>
      value?.includes(plus) ? false : true
    )
    .email("formValidations.invEmail"),
  password: Yup.string()
    .required("formValidations.pwdReq")
    .trim("formValidations.noSpace")
    .matches(passwordRegex, "formValidations.pwdRule")
    .strict(true),
});

export const login_validationSchema = Yup.object().shape({
  login_email: Yup.string()
    .required("formValidations.emailNumReq")
    .matches(emailPhoneRegex, "formValidations.invEmailNum"),
  password: Yup.string()
    .required("formValidations.pwdReq")
    .trim("formValidations.noSpace")
    .strict(true),
});

export const forgotPassword_validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("formValidations.emailReq")
    .email("formValidations.invEmail"),
});

export const resetPassword_validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("formValidations.pwdReq")
    .trim("formValidations.noSpace")
    .matches(passwordRegex, "formValidations.pwdRule")
    .strict(true),
  confirmPassword: Yup.string()
    .required("formValidations.pwdReq")
    .oneOf([Yup.ref("password"), null], "formValidations.pwdMatch"),
});

export const changePassword_validationSchema = Yup.object().shape({
  old_password: Yup.string().required("formValidations.pwdReq"),
  new_password: Yup.string()
    .required("formValidations.pwdReq")
    .trim("formValidations.noSpace")
    .matches(passwordRegex, "formValidations.pwdRule")
    .strict(true),
});

export const edit_personalInfo_validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("formValidations.fnameReq")
    // .matches(alphaOnly, "formValidations.fnameAlpha")
    .test("is-empty-space", "formValidations.fnameReq", (val) =>
      val?.trim().length ? true : false
    )
    .test(
      "has-special-char",
      "formValidations.fnameAlpha",
      (value) => value && !specialCharacters.test(value)
    ),
  last_name: Yup.string()
    .required("formValidations.lnameReq")
    // .matches(alphaOnly, "formValidations.lnameAlpha")
    .test("is-empty-space", "formValidations.lnameReq", (val) =>
      val?.trim().length ? true : false
    )
    .test(
      "has-special-char",
      "formValidations.lnameAlpha",
      (value) => value && !specialCharacters.test(value)
    ),
  mobile_no: Yup.string()
    .required("formValidations.numReq")
    .test(
      "is-number",
      "formValidations.invNum",
      (value) => value && value?.length === 12
    ),
  email: Yup.string()
    .required("formValidations.emailReq")
    .email("formValidations.invEmail"),
});

export const edit_personalInfo_validationSchema2 = Yup.object().shape({
  deliveryMethod: "",
  deliveryTime: "",
  governate: "",
  area: "",
  block: "",
  house_no: "",
  street: "",
  avenue: "",
  directions: "",
  floor_no: "",
});

export const createAddress_home_validationSchema = Yup.object().shape({
  governate: Yup.string().required("formValidations.govtReq").nullable(),
  area: Yup.string().required("formValidations.areaReq").nullable(),
  block: Yup.string().required("formValidations.blockReq"),
  house_no: Yup.string().required("formValidations.houseReq"),
  street: Yup.string().required("formValidations.streetReq"),
  avenue: "",
  directions: "",
});

export const createAddress_apt_validationSchema = Yup.object().shape({
  governate: Yup.string().required("formValidations.govtReq").nullable(),
  area: Yup.string().required("formValidations.areaReq").nullable(),
  block: Yup.string().required("formValidations.blockReq"),
  house_no: Yup.string().required("formValidations.houseReq"),
  floor_no: Yup.string().required("formValidations.apartReq"),
  street: Yup.string().required("formValidations.streetReq"),
  avenue: "",
  directions: "",
});

export const deliveryDetails_validationSchema = Yup.object().shape({
  deliveryMethod: Yup.string()
    .required("formValidations.delMethReq")
    .nullable(),
  deliveryTime: Yup.string().required("formValidations.delTimeReq").nullable(),
  governate: Yup.string().required("formValidations.govtReq").nullable(),
  area: Yup.string().required("formValidations.areaReq").nullable(),
  block: Yup.string().required("formValidations.blockReq"),
  house_no: Yup.string().required("formValidations.houseReq"),
  street: Yup.string().required("formValidations.streetReq"),
});

export const deliveryDetails_without_address_validationSchema =
  Yup.object().shape({
    deliveryMethod: Yup.string()
      .required("formValidations.delMethReq")
      .nullable(),
    deliveryTime: Yup.string()
      .required("formValidations.delTimeReq")
      .nullable(),
  });

export const deliveryDetails_for_apt_validationSchema = Yup.object().shape({
  deliveryMethod: Yup.string()
    .required("formValidations.delMethReq")
    .nullable(),
  deliveryTime: Yup.string().required("formValidations.delTimeReq").nullable(),
  governate: Yup.string().required("formValidations.govtReq").nullable(),
  area: Yup.string().required("formValidations.areaReq").nullable(),
  block: Yup.string().required("formValidations.blockReq"),
  house_no: Yup.string().required("formValidations.houseReq"),
  street: Yup.string().required("formValidations.streetReq"),
  floor_no: Yup.string().required("formValidations.apartReq"),
});
