import axios from "axios";
import moment from "moment";
import { BASE_URL } from "../../config/ApiConstants";

// Dashboard
export const loadDashboardAsync = async () => {
  let formData = new FormData();
  formData.append("lang_id", localStorage.getItem("lang_id") || 1);
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("jwt", localStorage.getItem("token"));
  try {
    const data = await axios.post(
      `${BASE_URL}/mypackagewithorderdates`,
      formData
    );
    return data;
  } catch (error) {
    return error;
  }
};

// My meals
export const loadMyMealsAsync = async (data) => {
  let date = moment(new Date()).format("DD-MM-yyyy");
  let scheduleDayNumber = data?.order_dates_array_ws?.filter((e) => {
    return date == e.schedule_date;
  });
  let formData = new FormData();
  formData.append("sub_package_id", data?.sub_package_id);
  formData.append("user_id", localStorage.getItem("userId"));
  formData.append("jwt", localStorage.getItem("token"));
  formData.append("order_id", data?.order_id);
  formData.append("date", data?.selected_date ? data?.selected_date : date);
  formData.append("language_id", localStorage.getItem("lang_id") || 1);
  formData.append(
    "days_master_id",
    scheduleDayNumber?.[0]?.schdeule_day_number
  );

  try {
    const data = await axios.post(`${BASE_URL}/getMyMeal`, formData);
    return data;
  } catch (error) {
    return error;
  }
};
