import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import { signup_validationSchema } from "../../constants/Formik/FormikValidations";
import { signup_initialValues } from "../../constants/Formik/FormikInitialValues";
import FormikController from "../../constants/Formik/FormikController";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../Store/Auth/Action";
import Loader from "../Loader.js/index.js";
import ToastComponent from "../Toast";
import { useNavigate } from "react-router-dom";
import { baseName } from "../../config/ApiConstants";
import { getCuurentUser, userDetails } from "../../Store/User/Action";
import { useTranslation } from "react-i18next";

function SignUp(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(({ authUser }) => authUser);
  const { t } = useTranslation();
  const expression = "signup";
  const [inputType, setInputType] = useState("password");
  const updateInputType = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };
  const onSubmit = (values) => {
    values.email = values?.signup_email;
    values.firstname = values?.firstname.trim();
    values.lastname = values?.lastname.trim();
    values.mobile_no = values?.mobile_no.slice(1);
    dispatch(createUser(values));
  };

  useEffect(() => {
    if (userData?.user && !userData?.loading) {
      props.handleClose();
      dispatch(getCuurentUser());
      if (!localStorage.getItem("toD")) {
        navigate(baseName + "dashboard");
      } else {
        localStorage.removeItem("toD");
      }
    }
  }, [!userData?.loading]);

  return (
    <>
      {userData?.loading && <Loader />}

      <Modal.Header closeButton>
        <Modal.Title>{t(`${expression}.title`)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="txt-grey">{t(`${expression}.desc`)}</p>

        <Formik
          initialValues={signup_initialValues}
          validationSchema={signup_validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, setFieldValue, values }) => {
            return (
              <Form className="authentication-form signup-form signupdesk">
                <div className="double">
                  <div className="form-group form-fname">
                    <FormikController
                      control="input"
                      type="text"
                      name="firstname"
                      className="form-control "
                      placeholder={t(`${expression}.input1`)}
                      errors={touched?.firstname && errors}
                    />
                  </div>

                  <div className="form-group form-lname">
                    <FormikController
                      control="input"
                      type="text"
                      name="lastname"
                      className="form-control"
                      placeholder={t(`${expression}.input2`)}
                      errors={touched?.lastname && errors}
                    />
                  </div>
                </div>

                <div className="form-group ">
                  <FormikController
                    control="inputMob"
                    type="text"
                    name="mobile_no"
                    placeholder={t(`${expression}.input3`)}
                    errors={touched?.mobile_no && errors}
                    className="mobileNumber"
                    onChange={(e) => setFieldValue("mobile_no", e, true)}
                    floatingLabel={true}
                    value={values?.mobile_no}
                  />
                </div>

                <div className="form-group">
                  <FormikController
                    control="input"
                    type="email"
                    name="signup_email"
                    className="form-control"
                    placeholder={t(`${expression}.input4`)}
                    errors={touched?.signup_email && errors}
                  />
                </div>

                <div className="form-group iconformsignnup">
                  <FormikController
                    control="inputwithP"
                    type={inputType}
                    name="password"
                    className="form-control"
                    placeholder={t(`${expression}.input5`)}
                    inputType={inputType}
                    onClick={updateInputType}
                    errors={touched?.password && errors}
                  />
                  <small className="default-validation">
                    *{t(`formValidations.pwdRule`)}
                  </small>
                  {/* <button type="button" onClick={updateInputPType} className="icon-eyes-password btn btn-link"></button> */}
                </div>

                <Button variant="primary" className="btn-fill" type="submit">
                  {t(`login.signup`)}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer className="flex-column">
        <div className="mx-0 or-blk">
          <span className="txt-or">{t(`login.or`)}</span>
        </div>
        <div className="m-0 bottom-blk">
          <p>{t(`${expression}.already`)}</p>
          <Button
            variant="light"
            className="btn-outline"
            onClick={() => props.setComponentType("login")}
          >
            {t(`${expression}.login`)}
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
}

export default SignUp;
