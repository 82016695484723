import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { FAQ, LOAD_PLANS } from '../Actions';

import {
    faqSuccess,
    faqError
} from './Action';
import { loadFaqAsync } from './Apis';



// Faq
function* loadFaq({ payload }) {
    const response = yield loadFaqAsync(payload);
    const { data } = response;
    try {
        if (data.data) {
            yield put(faqSuccess(data.data));
        }

        else {
            yield put(faqError(data.error));
        }
    }
    catch (err) {
        yield put(faqError(data?.error));
    }
}

export function* watchLoadFaq() {
    yield takeEvery(FAQ, loadFaq);
}

export default function* rootSaga() {
    yield all([
        fork(watchLoadFaq)
    ]);
}