import { all, fork, put, takeEvery } from "redux-saga/effects";
import { alrtTypes } from "../../constants/Utils";
import { DATE_SUSPEND, DATE_UN_SUSPEND, LOAD_MEAL_CAL } from "../Actions";
import { setMessage } from "../AlertMessage/Action";
import { loadDashboard } from "../Dashboard/Action";

import {
  loadMealCalSuccess,
  loadMealCalError,
  dateSuspendSuccess,
  dateSuspendError,
  loadMealCal,
  dateUnSuspendSuccess,
  dateUnSuspendError,
} from "./Action";
import { loadMealCalAsync, dateSuspendAsync, dateUnSuspendAsync } from "./Apis";
import i18n from "../../i18n/config";

// Meal Cal
function* loadMealsCal({ payload }) {
  const response = yield loadMealCalAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(loadMealCalSuccess(data.data));
    } else {
      yield put(loadMealCalError(data.error));
    }
  } catch (err) {
    yield put(loadMealCalError(data?.error));
  }
}

export function* watchLoadMealCal() {
  yield takeEvery(LOAD_MEAL_CAL, loadMealsCal);
}

// date suspend
function* dateSuspend({ payload }) {
  const response = yield dateSuspendAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      payload?.dispatch(loadMealCal(payload?.order_id));
      payload.dispatch(loadDashboard());
      yield put(
        dateSuspendSuccess({
          data: data.data,
          freeze_dates: payload?.freeze_dates,
        })
      );
      yield put(
        setMessage({
          toastMessage: i18n.t("apiResponse.freeze"),
          code: alrtTypes.SUCCESS,
        })
      );
    } else {
      yield put(dateSuspendError(data.error_msg));
      yield put(
        setMessage({
          toastMessage: data.error_msg,
          code: alrtTypes.WARNING,
        })
      );
      payload?.dispatch(loadMealCal(payload?.order_id));
    }
  } catch (err) {
    yield put(dateSuspendError(data?.error_msg));
    yield put(
      setMessage({
        toastMessage: data?.error_msg,
        code: alrtTypes.WARNING,
      })
    );
    payload?.dispatch(loadMealCal(payload?.order_id));
  }
}

export function* watchDateSuspend() {
  yield takeEvery(DATE_SUSPEND, dateSuspend);
}

function* dateUnSuspend({ payload }) {
  const response = yield dateUnSuspendAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      payload?.dispatch(loadMealCal(payload?.order_id));
      payload.dispatch(loadDashboard());
      yield put(
        dateUnSuspendSuccess({
          data: data.data,
          unfreeze_dates: payload?.unfreeze_dates,
        })
      );
      yield put(
        setMessage({
          toastMessage: i18n
            .t("apiResponse.freeze")
            .replace("paused", "Unfreezed"),
          code: alrtTypes.SUCCESS,
        })
      );
    } else {
      yield put(dateUnSuspendError(data.error_msg));
      yield put(
        setMessage({
          toastMessage: data.error_msg,
          code: alrtTypes.WARNING,
        })
      );
      payload?.dispatch(loadMealCal(payload?.order_id));
    }
  } catch (err) {
    yield put(dateSuspendError(data?.error_msg));
    yield put(
      setMessage({
        toastMessage: data?.error_msg,
        code: alrtTypes.WARNING,
      })
    );
    payload?.dispatch(loadMealCal(payload?.order_id));
  }
}

export function* watchDateUnSuspend() {
  yield takeEvery(DATE_UN_SUSPEND, dateUnSuspend);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadMealCal),
    fork(watchDateSuspend),
    fork(watchDateUnSuspend),
  ]);
}
