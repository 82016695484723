import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { baseName } from "../../config/ApiConstants";
import { AUTHPATHS, PATHS } from "../../constants/Paths";
import { alrtTypes } from "../../constants/Utils";
import { LOAD_PLANS } from "../Actions";
import { setMessage } from "../AlertMessage/Action";

import { loadPlansSuccess, loadPlansError } from "./Action";
import { loadPlansAsync } from "./Apis";
import i18n from "../../i18n/config";

// Plans
function* loadPlans({ payload }) {
  const response = yield loadPlansAsync(payload);
  const {
    data: { data: pakageList },
  } = response[0];
  const {
    data: { data: mealList },
  } = response[1];
  try {
    if (response.length) {
      yield put(
        loadPlansSuccess({
          pakageList: pakageList,
          mealList: mealList,
        })
      );
    } else {
      yield put(loadPlansError(new Error(i18n.t("apiResponse.tryAgain"))));
    }
  } catch (err) {
    yield put(loadPlansError(new Error(i18n.t("apiResponse.tryAgain"))));
    yield put(
      setMessage({
        toastMessage: i18n.t("apiResponse.serverErr"),
        code: alrtTypes.WARNING,
      })
    );
  }
}

export function* watchLoadPlans() {
  yield takeEvery(LOAD_PLANS, loadPlans);
}

export default function* rootSaga() {
  yield all([fork(watchLoadPlans)]);
}
