import moment from 'moment';
import { roundDownToNearest10 } from '../../constants/Utils';
import {
    USER_DETAILS,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_ERROR,
    UPDATE_PERSONAL_INFO,
    UPDATE_PERSONAL_INFO_SUCCESS,
    UPDATE_PERSONAL_INFO_ERROR,
    UPDATE_CALORIE,
    UPDATE_CALORIE_SUCCESS,
    UPDATE_CALORIE_ERROR,
    ADD_ADDRESS,
    ADD_ADDRESS_SUCCESS,
    ADD_ADDRESS_ERROR,
    SET_PRIMARY_ADDRESS,
    SET_PRIMARY_ADDRESS_SUCCESS,
    SET_PRIMARY_ADDRESS_ERROR,
    GET_USER_ADDRESS_ONLY,
    GET_USER_ERROR_ADDRESS_ONLY,
    GET_USER_SUCCESS_ADDRESS_ONLY,
    SAVE_PHYSICAL_DATA,
    SAVE_PHYSICAL_DATA_SUCCESS,
    SAVE_PHYSICAL_DATA_ERROR,
    GET_CURRENT_USER,
    GET_CURRENT_USER_SUCCESS,
    GET_CURRENT_USER_ERROR,
    ADD_TOADY_USER_WEIGHT,
    ADD_TOADY_USER_WEIGHT_SUCCESS,
    ADD_TOADY_USER_WEIGHT_ERROR,
    RESET_USER_SUCCESS,
    MEAL_FAV_DONT_SHOW,
    MEAL_FAV_DONT_SHOW_SUCCESS,
    MEAL_FAV_DONT_SHOW_ERROR,
    UPDATE_DELIVERY_TYPE,
    UPDATE_DELIVERY_TYPE_SUCCESS,
    UPDATE_DELIVERY_TYPE_ERROR,
} from '../Actions';


const INIT_STATE = {
    user: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER_DETAILS:
            return { ...state, loading: true, error: '' };
        case USER_DETAILS_SUCCESS:
            return {
                ...state, loading: false,
                allergyList: action.payload.allergyList,
                dislikesList: action.payload.dislikesList,
                // userData: action.payload.userData,
                physicalData: action.payload.physicalData,
                allAllergies: action.payload.allAllergies,
                allDislikes: action.payload.allDislikes,
                addressList: action.payload.addressList,
                weightHistory: action.payload.weightHistory,
                error: ''
            };
        case USER_DETAILS_ERROR:
            return { ...state, loading: false, user: null, userData: null, error: action.payload.message };

        // Update Personal info
        case UPDATE_PERSONAL_INFO:
            return { ...state, loading: true, error: '' };
        case UPDATE_PERSONAL_INFO_SUCCESS:
            if (action.payload?.type === "epi") {
                state.currentUser = { ...state.currentUser, ...action.payload }
            } else {
                state.allergyList = action.payload.allergies?.map((elem) => {
                    return { main_allergy_id: elem.value, name: elem.label }
                })
                state.dislikesList = action.payload.dislikes?.map((elem) => {
                    return { id: elem.value, name: elem.label, type: elem.group ? 'dislike_category' : 'ingredient', mapped_ingredients: elem.mappedIngredients }
                })
            }
            return {
                ...state, loading: false,
                error: ''
            };
        case UPDATE_PERSONAL_INFO_ERROR:
            return { ...state, loading: false, error: action.payload.message };
        // Update Personal info
        case UPDATE_CALORIE:
            return { ...state, loading: true, error: '' };
        case UPDATE_CALORIE_SUCCESS:
            state.physicalData[0].calorie_count = action.payload;
            return { ...state, loading: false, error: '' };
        case UPDATE_CALORIE_ERROR:
            return { ...state, loading: false, error: action.payload.message };

        // Add Address 
        case ADD_ADDRESS:
            return { ...state, loading: true, error: '' };
        case ADD_ADDRESS_SUCCESS:
            state.new_address_id = action.payload;
            return { ...state, loading: false, error: '' };
        case ADD_ADDRESS_ERROR:
            return { ...state, loading: false, error: action.payload.message };

        // Set Primary Address 
        case SET_PRIMARY_ADDRESS:
            return { ...state, loading: true, error: '' };
        case SET_PRIMARY_ADDRESS_SUCCESS:
            return { ...state, loading: false, error: '' };
        case SET_PRIMARY_ADDRESS_ERROR:
            return { ...state, loading: false, error: action.payload.message };


        // Get user address only 
        case GET_USER_ADDRESS_ONLY:
            return { ...state, loading: true, error: '' };
        case GET_USER_SUCCESS_ADDRESS_ONLY:
            state.addressList = action?.payload
            return { ...state, loading: false, userAddress: action?.payload, error: '' };
        case GET_USER_ERROR_ADDRESS_ONLY:
            return { ...state, loading: false, userAddress: null, error: action.payload.message };

        // Save physical data
        case SAVE_PHYSICAL_DATA:
            return { ...state, loading: true, error: '' };
        case SAVE_PHYSICAL_DATA_SUCCESS:
            state.physicalData[0].height = action?.payload?.height;
            state.physicalData[0].weight = action?.payload?.pWeight;
            state.physicalData[0].tar_weight = action?.payload?.tWeight;
            state.physicalData[0].fitnes_goal = action?.payload?.goal;
            state.physicalData[0].user_gender = action?.payload?.gender;
            state.physicalData[0].calorie_count = roundDownToNearest10(action?.payload?.result);
            state.physicalData.latest_weight[0].weight = action?.payload?.pWeight;
            return { ...state, loading: false, error: '' };
        case SAVE_PHYSICAL_DATA_ERROR:
            return { ...state, loading: false, error: action.payload.message };


        // Get Current user 
        case GET_CURRENT_USER:
            return { ...state, loading: true, error: '' };
        case GET_CURRENT_USER_SUCCESS:
            state.addressList = action?.payload
            return { ...state, loading: false, currentUser: action.payload, error: '' };
        case GET_CURRENT_USER_ERROR:
            return { ...state, loading: false, currentUser: null, error: action.payload.message };

        // Add Today user weight
        case ADD_TOADY_USER_WEIGHT:
            return { ...state, loading: true, error: '' };
        case ADD_TOADY_USER_WEIGHT_SUCCESS:
            // Setting weight for graph
            if (!state.weightHistory) {
                state.weightHistory = [{ weight: action?.payload, created_at: moment(new Date()).format("YYYY-MM-DD") }]
            } else {
                const weightIndex = state?.weightHistory?.findIndex((elem) => elem.created_at == moment(new Date()).format("YYYY-MM-DD"))
                if (weightIndex !== -1) {
                    state?.weightHistory.splice(weightIndex, 1, { weight: action?.payload, created_at: moment(new Date()).format("YYYY-MM-DD") })
                } else {
                    state.weightHistory = [...state.weightHistory, { weight: action?.payload, created_at: moment(new Date()).format("YYYY-MM-DD") }]
                }
            }
            // setting weight for dashboard
            if (!state.physicalData?.latest_weight.length >= 1) {
                state.physicalData.latest_weight = [{ weight: action?.payload, created_at: moment(new Date()).format("YYYY-MM-DD") }]
            } else {
                state.physicalData.latest_weight = [{ weight: action?.payload, created_at: moment(new Date()).format("YYYY-MM-DD") }]
            }
            return { ...state, loading: false, error: '' };
        case ADD_TOADY_USER_WEIGHT_ERROR:
            return { ...state, loading: false, error: action.payload.message };


        // Fav dont show
        case MEAL_FAV_DONT_SHOW:
            return { ...state, loading: true, error: '' };
        case MEAL_FAV_DONT_SHOW_SUCCESS:
            state.currentUser.fav_notification = action?.payload;
            return { ...state, loading: false, error: '' };
        case MEAL_FAV_DONT_SHOW_ERROR:
            return { ...state, loading: false, error: action.payload.message };


        case UPDATE_DELIVERY_TYPE:
            return { ...state, loading: true, error: '' };
        case UPDATE_DELIVERY_TYPE_SUCCESS:
            state.promocode = null;
            return { ...state, loading: false, error: '' };
        case UPDATE_DELIVERY_TYPE_ERROR:
            return { ...state, loading: false, error: action.payload.message };


        // Reset user
        case RESET_USER_SUCCESS:
            state.allergyList = []
            state.dislikesList = []
            state.physicalData = []
            state.allAllergies = []
            state.allDislikes = []
            state.addressList = []
            state.weightHistory = []
            return { ...state, loading: false, currentUser: null, error: '' };

        default: return { ...state };

    }
}