import {
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
} from '../Actions';

// SignUp
export const createUser = (user) => ({
    type: CREATE_USER,
    payload: user
});
export const createUserSuccess = (user) => ({
    type: CREATE_USER_SUCCESS,
    payload: user
});
export const createUserError = (message) => ({
    type: CREATE_USER_ERROR,
    payload: { message }
});

// Login
export const login = (user, navigate) => ({
    type: LOGIN,
    payload: { user, navigate }
});
export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    payload: user
});
export const loginError = (message) => ({
    type: LOGIN_ERROR,
    payload: { message }
});

// Forgot Password
export const forgotPassword = (email) => ({
    type: FORGOT_PASSWORD,
    payload: email
});
export const forgotPasswordSuccess = (user) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: user
});
export const forgotPasswordError = (message) => ({
    type: FORGOT_PASSWORD_ERROR,
    payload: { message }
});
// Reset Password
export const resetPassword = (user, isForgotPassword) => ({
    type: RESET_PASSWORD,
    payload: { user, isForgotPassword }
});
export const resetPasswordSuccess = (user) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: user
});
export const resetPasswordError = (message) => ({
    type: RESET_PASSWORD_ERROR,
    payload: { message }
});
// Logout
export const logout = (navigate) => ({
    type: LOGOUT,
    payload: navigate
});
export const logoutSuccess = (user) => ({
    type: LOGOUT_SUCCESS,
    payload: user
});
export const logoutError = (message) => ({
    type: LOGOUT_ERROR,
    payload: { message }
});