import { adultFemale, adultFemaleGain, adultMale, adultMaleGain, adultMaleMaintain, femaleBMI, gainWeight, genderArray, loseWeight, userTargets, youthFemale, youthMale } from '../../constants/Utils';
import {
    CALCULATE_CALORIES,
    CALCULATE_CALORIES_SUCCESS,
    CALCULATE_CALORIES_ERROR,
    SAVE_PHYSICAL_DATA,
    SAVE_PHYSICAL_DATA_SUCCESS,
    SAVE_PHYSICAL_DATA_ERROR,
} from '../Actions';


export const INIT_STATE = {
    goal: '',
    gender: '',
    age: '',
    height: '',
    heightInFt: '',
    heightin: '',
    pWeight: '',
    tWeight: '',
    activity: '',
    activityIndex: '',
    result: '',
    unitHeight: '',
    unitPresentWeight: '',
    unitTargetWeight: '',
    isDone: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CALCULATE_CALORIES:
            return { ...state, loading: true, error: '' };
        case CALCULATE_CALORIES_SUCCESS:
            state = { ...state, ...action.payload.value };
            if (action.payload.type === "step4") {
                // for youth(male and female)  ;
                if (+state.age <= 15) {
                    // - weight loss
                    if (state.goal === userTargets[0]) {
                        // -male
                        if (state.gender === genderArray[0]) {
                            const bmr = youthMale(+state.age, +state.pWeight, +state.height);
                            state.result = loseWeight(bmr, state.activity);
                        }
                        // -female
                        if (state.gender === genderArray[1]) {
                            const bmr = youthFemale(+state.age, +state.pWeight, +state.height);
                            state.result = loseWeight(bmr, state.activity);
                        }
                    }

                    // -weigt gain
                    if (state.goal === userTargets[1]) {
                        // -male
                        if (state.gender === genderArray[0]) {
                            const bmr = youthMale(+state.age, +state.pWeight, +state.height);
                            state.result = gainWeight(bmr, state.activity);
                        }
                        // -female
                        if (state.gender === genderArray[1]) {
                            const bmr = youthFemale(+state.age, +state.pWeight, +state.height);
                            state.result = gainWeight(bmr, state.activity);
                        }
                    }

                    // - weight maintain
                    if (state.goal === userTargets[2]) {
                        // -male
                        if (state.gender === genderArray[0]) {
                            const bmr = youthMale(+state.age, +state.pWeight, +state.height);
                            state.result = bmr * state.activity;
                        }
                        // -female
                        if (state.gender === genderArray[1]) {
                            const bmr = youthFemale(+state.age, +state.pWeight, +state.height);
                            state.result = bmr * state.activity;
                        }
                    }
                    // adult above(15)
                } else {
                    // weight lose
                    if (state.goal === userTargets[0]) {
                        // -male
                        if (state.gender === genderArray[0]) {
                            const bmr = adultMale(+state.age, +state.pWeight, +state.height);
                            state.result = loseWeight(bmr, state.activity);
                        }
                        // -Female
                        if (state.gender === genderArray[1]) {
                            const bmr = adultFemale(+state.age, +state.pWeight, +state.height);
                            state.result = loseWeight(bmr, state.activity);
                        }
                    }
                    // weight gain
                    if (state.goal === userTargets[1]) {
                        // -male
                        if (state.gender === genderArray[0]) {
                            const bmr = adultMaleGain(+state.age, +state.pWeight, +state.height);
                            state.result = gainWeight(bmr, state.activity);
                        }
                        // -Female
                        if (state.gender === genderArray[1]) {
                            const bmr = adultFemaleGain(+state.age, +state.pWeight, +state.height);
                            state.result = gainWeight(bmr, state.activity);
                        }
                    }
                    // weight maintain
                    if (state.goal === userTargets[2]) {
                        // -male
                        if (state.gender === genderArray[0]) {
                            const bmr = adultMaleGain(+state.age, +state.pWeight, +state.height);
                            state.result = bmr * state.activity;
                        }
                        // -Female
                        if (state.gender === genderArray[1]) {
                            const bmr = adultFemaleGain(+state.age, +state.pWeight, +state.height);
                            state.result = bmr * state.activity;
                        }
                    }
                }
                state.isDone = true;
            }
            return { ...state, loading: false, error: '' };
        case CALCULATE_CALORIES_ERROR:
            return { ...state, loading: false, cc: null, error: action.payload.message };

        default: return { ...state };
    }
}