import React from "react";
import {
  Input,
  PassswordInputWithEyeIcon,
  PhoneNumberInput,
  Select,
} from "./FormComponents";

const FormikController = (props) => {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "inputwithP":
      return <PassswordInputWithEyeIcon {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "inputMob":
      return <PhoneNumberInput {...rest} />;
    default:
      return null;
  }
};

export default FormikController;
