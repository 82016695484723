import axios from "axios";
import { PATHS } from "../constants/Paths";
import { onLogout } from "../constants/Utils";
import { BASE_URL } from "./ApiConstants";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use((response) => {
  if (response?.data?.error === 401) {
    return onRejected(response);
  }
  return response;
}, onRejected);

function onRejected(error) {
  if (error.data?.error === 401) {
    // Call refresh token API only if token exists
    if (!localStorage.getItem("refreshToken")) {
      onLogout();
      setTimeout(() => {
        window.location.href = window.location.origin + PATHS.HOME;
      }, 0);
      return Promise.reject(error);
    }

    const formData = new FormData();
    formData.append("refresh_token", localStorage.getItem("refreshToken"));

    // Call refresh token API only after expiry time
    // if (
    //   localStorage.getItem("expiryAt") &&
    //   localStorage.getItem("expiryAt") <= Date.now()
    // ) {
    //   onLogout();
    //   window.location.reload();
    //   return Promise.reject(error);
    // }

    return axios
      .post(BASE_URL + "/update_jwt", formData)
      .then((res) => {
        if (res?.data?.error !== 403) {
          error.config.headers.Authorization =
            "Bearer " + res.data.data.jwt_token;
          localStorage.setItem("token", res.data?.data?.jwt_token);
          localStorage.setItem("refreshToken", res.data?.data?.refresh_token);
          localStorage.setItem(
            "expiryAt",
            res.data?.data?.refresh_token_expiry * 1000
          );

          setTimeout(() => {
            window.location.href =
              window.location.origin + window.location.pathname;
          }, 0);

          return error.config;
        } else throw res;
      })
      .catch((error) => {
        onLogout();
        window.location.reload();
        return Promise.reject(error);
      });
  }
  if (error.response?.data?.error === 403) {
    onLogout();
  }
  return Promise.reject(error);
}
