import {
    FAQ,
    FAQ_SUCCESS,
    FAQ_ERROR,
} from '../Actions';


const INIT_STATE = {
    faq: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FAQ:
            return { ...state, loading: true, error: '' };
        case FAQ_SUCCESS:
            return { ...state, loading: false, faq: action.payload, error: '' };
        case FAQ_ERROR:
            return { ...state, loading: false, faq: null, error: action.payload.message };
        default: return { ...state };

    }
}