import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { PATHS } from '../../constants/Paths';
import { alrtTypes } from '../../constants/Utils';
import { ADD_DAYS_TO_PACKAGE, GET_MY_PACKAGE, SAVE_EX_SUB_DATA_LOCAL } from '../Actions';
import { setMessage } from '../AlertMessage/Action';

import {
    getMyPackageSuccess,
    getMyPackageError,
    saveExSubLocalSuccess,
    saveExSubLocalError,
    addDaysToPackageError,
    addDaysToPackageSuccess
} from './Action';
import { addDaysToPackAsync, loadMyPackageAsync } from './Apis';
import { loadDashboard } from "../Dashboard/Action"
import moment from 'moment';




// My Package
function* loadMyPackage({ payload }) {
    const response = yield loadMyPackageAsync(payload);
    const { data } = response;
    try {
        if (data.data) {
            yield put(getMyPackageSuccess(data.data));
        }

        else {
            yield put(getMyPackageError(data.error));
        }
    }
    catch (err) {
        yield put(getMyPackageError(data?.error));
    }
}

export function* watchLoadMyPackage() {
    yield takeEvery(GET_MY_PACKAGE, loadMyPackage);
}



// Save data local
function* saveExSubLocal({ payload }) {
    if (payload) {
        yield put(saveExSubLocalSuccess(payload));
    }

    else {
        yield put(saveExSubLocalError(payload));
    }
}

export function* watchSaveExSubLocal() {
    yield takeEvery(SAVE_EX_SUB_DATA_LOCAL, saveExSubLocal);
}


// Add days to Package
function* addDaysTopack({ payload }) {
    const response = yield addDaysToPackAsync(payload.data);
    let date = moment(new Date()).format('DD-MM-yyyy')

    const { data } = response;
    try {
        if (data.data) {
            yield put(addDaysToPackageSuccess(data.data));
            payload.dispatch(loadDashboard());
            if (data?.data?.totAmount == 0)
                payload.navigate(`${PATHS.PAYMENT_SUCCESS}?Result=PAID&date=${date}&package_name=${payload?.package_name}&Amount=${data?.data?.totAmount}&purchased_from=web`)
            else if (data?.data?.payment_url) {
                window.location.href = data?.data?.payment_url;
            }
        }
        else {
            yield put(addDaysToPackageError(data));
            yield put(setMessage({
                toastMessage: data?.error_msg,
                code: alrtTypes.WARNING
            }));
        }
    }
    catch (err) {
        yield put(addDaysToPackageError(err));
        yield put(setMessage({
            toastMessage: data?.error_msg,
            code: alrtTypes.WARNING
        }));
    }
}

export function* watchAddDaysToPack() {
    yield takeEvery(ADD_DAYS_TO_PACKAGE, addDaysTopack);
}

export default function* rootSaga() {
    yield all([
        fork(watchLoadMyPackage),
        fork(watchSaveExSubLocal),
        fork(watchAddDaysToPack),


    ]);
}