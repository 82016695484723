import {
    TERMS_AND_CONDITIONS,
    TERMS_AND_CONDITIONS_SUCCESS,
    TERMS_AND_CONDITIONS_ERROR,
} from '../Actions';

// Plans
export const t_c = (user) => ({
    type: TERMS_AND_CONDITIONS,
    payload: user
});
export const t_cSuccess = (user) => ({
    type: TERMS_AND_CONDITIONS_SUCCESS,
    payload: user
});
export const t_cError = (message) => ({
    type: TERMS_AND_CONDITIONS_ERROR,
    payload: { message }
});
