import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { TERMS_AND_CONDITIONS } from '../Actions';

import {
    t_cSuccess,
    t_cError
} from './Action';
import { loadTcAsync } from './Apis';



// Terms and conditions
function* loadTc({ payload }) {
    const response = yield loadTcAsync(payload);
    const { data } = response;
    try {
        if (data.data) {
            yield put(t_cSuccess(data.data));
        }

        else {
            yield put(t_cError(data.error));
        }
    }
    catch (err) {
        yield put(t_cError(data?.error));
    }
}

export function* watchLoadTc() {
    yield takeEvery(TERMS_AND_CONDITIONS, loadTc);
}

export default function* rootSaga() {
    yield all([
        fork(watchLoadTc)
    ]);
}