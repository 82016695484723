import {
    GET_MY_PACKAGE,
    GET_MY_PACKAGE_SUCCESS,
    GET_MY_PACKAGE_ERROR,
    SAVE_EX_SUB_DATA_LOCAL,
    SAVE_EX_SUB_DATA_LOCAL_SUCCESS,
    SAVE_EX_SUB_DATA_LOCAL_ERROR,
    ADD_DAYS_TO_PACKAGE,
    ADD_DAYS_TO_PACKAGE_SUCCESS,
    ADD_DAYS_TO_PACKAGE_ERROR,
} from '../Actions';


const INIT_STATE = {
    myPackage: [],
    days: '',
    amount: ''
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MY_PACKAGE:
            return { ...state, loading: true, error: '' };
        case GET_MY_PACKAGE_SUCCESS:
            return { ...state, loading: false, myPackage: action.payload, error: '' };
        case GET_MY_PACKAGE_ERROR:
            return { ...state, loading: false, myPackage: null, error: action.payload.message };

        // save data local
        case SAVE_EX_SUB_DATA_LOCAL:
            return { ...state, loading: true, error: '' };
        case SAVE_EX_SUB_DATA_LOCAL_SUCCESS:
            return { ...state, loading: false, ...action.payload, error: '' };
        case SAVE_EX_SUB_DATA_LOCAL_ERROR:
            return { ...state, loading: false, myPackage: null, error: action.payload.message };

        // Add days to pack
        case ADD_DAYS_TO_PACKAGE:
            return { ...state, loading: true, error: '' };
        case ADD_DAYS_TO_PACKAGE_SUCCESS:
            return { ...state, loading: false, addDays: action.payload, error: '' };
        case ADD_DAYS_TO_PACKAGE_ERROR:
            return { ...state, loading: false, addDays: null, error: action.payload.message };
        default: return { ...state };
    }
}