import {
    LOAD_SUBSCRIPTION,
    LOAD_SUBSCRIPTION_SUCCESS,
    LOAD_SUBSCRIPTION_ERROR,
    PROMOCODE_IS_VALID,
    PROMOCODE_IS_VALID_SUCCESS,
    PROMOCODE_IS_VALID_ERROR,
    PURCHASE_PACKAGE,
    PURCHASE_PACKAGE_SUCCESS,
    PURCHASE_PACKAGE_ERROR,
    GET_END_DATE,
    GET_END_DATE_SUCCESS,
    GET_END_DATE_ERROR,
    MAKE_PROMO_NULL,
    MAKE_PROMO_NULL_SUCCESS,
    UPDATE_DELIVERY_TYPE,
    UPDATE_DELIVERY_TYPE_SUCCESS,
    UPDATE_DELIVERY_TYPE_ERROR,
    MAKE_PROMO_NULL_ERROR,
} from '../Actions';

// Subscriptions
export const loadSubscription = (plan_id) => ({
    type: LOAD_SUBSCRIPTION,
    payload: plan_id
});
export const loadSubscriptionSuccess = (user) => ({
    type: LOAD_SUBSCRIPTION_SUCCESS,
    payload: user
});
export const loadSubscriptionError = (message) => ({
    type: LOAD_SUBSCRIPTION_ERROR,
    payload: { message }
});
// Promocode
export const promocodeIsValid = (coupen) => ({
    type: PROMOCODE_IS_VALID,
    payload: coupen
});
export const promocodeIsValidSuccess = (_data) => ({
    type: PROMOCODE_IS_VALID_SUCCESS,
    payload: _data
});
export const promocodeIsValidError = (message) => ({
    type: PROMOCODE_IS_VALID_ERROR,
    payload: { message }
});


// Purchase Package
export const purchasePackage = (_data, navigate, plan_name, amnt) => ({
    type: PURCHASE_PACKAGE,
    payload: { _data, navigate, plan_name, amnt }
});
export const purchasePackageSuccess = (_data) => ({
    type: PURCHASE_PACKAGE_SUCCESS,
    payload: _data
});
export const purchasePackageError = (message) => ({
    type: PURCHASE_PACKAGE_ERROR,
    payload: { message }
});

// Get end date
export const getEndDate = (data) => ({
    type: GET_END_DATE,
    payload: data
});
export const getEndDateSuccess = (_data) => ({
    type: GET_END_DATE_SUCCESS,
    payload: _data
});
export const getEndDateError = (message) => ({
    type: GET_END_DATE_ERROR,
    payload: { message }
});

// make promo null
export const makePromoNull = (data) => ({
    type: MAKE_PROMO_NULL,
    payload: data
});

export const makePromoNullSuccess = (data) => ({
    type: MAKE_PROMO_NULL_SUCCESS,
    payload: data
});
export const makePromoNullError = (data) => ({
    type: MAKE_PROMO_NULL_ERROR,
    payload: data
});


