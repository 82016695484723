import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const images = require.context("../../assets/icons", false, /\.png$/);

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(
    localStorage.getItem("lang") || i18n.language || "en"
  );

  const switchAndGetLanguage = () => (lang === "en" ? "ar" : "en");

  const changeLanguage = () => {
    i18n.changeLanguage(switchAndGetLanguage());
    setLang(switchAndGetLanguage());

    localStorage.setItem("lang", switchAndGetLanguage());
    localStorage.setItem("lang_id", switchAndGetLanguage() === "en" ? 1 : 2);

    switchAndGetLanguage() === "ar"
      ? document.body.classList.add("lang-ar")
      : document.body.classList.remove("lang-ar");
  };

  useEffect(() => {
    if (lang === "ar") {
      document.body.classList.add("lang-ar");
    } else {
      localStorage.setItem("lang_id", 1);
      localStorage.setItem("lang", "en");
    }
  }, []);

  return (
    <div className="flag" onClick={changeLanguage}>
      <img src={images(`./${switchAndGetLanguage()}.png`)} alt="Flag" />
      <span>{switchAndGetLanguage().toUpperCase()}</span>
    </div>
  );
};

export default LanguageSwitcher;
