import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, Form } from "formik";

import "./style.scss";
import { forgotPassword_initialValues } from "../../constants/Formik/FormikInitialValues";
import { forgotPassword_validationSchema } from "../../constants/Formik/FormikValidations";
import FormikController from "../../constants/Formik/FormikController";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../Store/Auth/Action";
import Loader from "../Loader.js/index.js";
import { useTranslation } from "react-i18next";

function ForgotPassword(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const expression = "forgot";

  const user = useSelector(({ authUser }) => authUser);

  const onSubmit = (values) => {
    dispatch(forgotPassword(values));
  };

  useEffect(() => {
    if (user?.forgotPassword && !user?.loading) {
      props.handleClose();
    }
  }, [!user?.loading]);

  return (
    <>
      {user?.loading && <Loader />}

      <Modal.Header closeButton>
        <Modal.Title>{t(`${expression}.title`)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="txt-grey">{t(`${expression}.desc`)}</p>

        <Formik
          initialValues={forgotPassword_initialValues}
          validationSchema={forgotPassword_validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => {
            return (
              <Form className="authentication-form signup-form">
                <FormikController
                  control="input"
                  type="text"
                  name="email"
                  className="form-control"
                  placeholder={t(`${expression}.input1`)}
                  errors={touched?.email && errors}
                />

                <Button variant="primary" className="btn-fill" type="submit">
                  {t(`${expression}.send`)}
                </Button>

                <Button
                  variant="primary"
                  className="btn-backlogin"
                  type="submit"
                  onClick={() => props.setComponentType("login")}
                >
                  {t(`${expression}.back`)}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>

      <Modal.Footer className="flex-column">
        <div className="mx-0 or-blk">
          <span className="txt-or">{t(`login.or`)}</span>
        </div>

        <div className=" m-0 bottom-blk">
          <p>{t(`signup.already`)}</p>
          <Button
            variant="light"
            className="btn-outline"
            onClick={() => props.setComponentType("signup")}
          >
            {t(`login.signup`)}
          </Button>
        </div>
      </Modal.Footer>
    </>
  );
}

export default ForgotPassword;
