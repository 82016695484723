import { combineReducers } from 'redux';

import authUser from './Auth/Reducer';
import alertMessage from './AlertMessage/Reducer';
import home from './Home/Reducer';
import faq from './FAQ/Reducer';
import t_c from './TermsAndConditions/Reducer';
import user from './User/Reducer';
import cc from './CalculateCalories/Reducer';
import calorieplanDetails from './CaloriePlans/Reducer';
import planDetails from './PlanDetails/Reducer';
import subscription from './Subscription/Reducer';
import dashboard from './Dashboard/Reducer';
import mealCal from './MealCal/Reducer';
import mealList from './MealList/Reducer';
import extendSub from './ExtendSubscription/Reducer';

const reducers = combineReducers({
    authUser,
    alertMessage,
    home,
    faq,
    t_c,
    user,
    cc,
    calorieplanDetails,
    planDetails,
    subscription,
    dashboard,
    mealCal,
    mealList,
    extendSub

});

export default reducers;
