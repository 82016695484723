import { all, fork, put, takeEvery } from "redux-saga/effects";
import { alrtTypes } from "../../constants/Utils";
import {
  ADD_MEAL,
  FAV_MEAL,
  LOAD_MEAL_LIST,
  LOAD_MEAL_LIST_SINGLE_SHORT,
  LOAD_MY_MEAL,
  RESET_MEAL,
} from "../Actions";
import { setMessage } from "../AlertMessage/Action";
import { loadMyMeals } from "../Dashboard/Action";
import { loadMealCal } from "../MealCal/Action";

import {
  loadMealListSuccess,
  loadMealListError,
  loadMyMealSuccess,
  loadMyMealError,
  addMealSuccess,
  addMealError,
  mealListSingleShortSuccess,
  mealListSingleShortError,
  resetMealSuccess,
  loadMyMeal,
  favMealError,
  favMealSuccess,
} from "./Action";
import {
  addMealAsync,
  allMealsAsync,
  favMealAsync,
  loadMealListAsync2,
  loadMealListAsync3,
  loadMealListAsync4,
  loadMyMealAsync,
} from "./Apis";
import i18n from "../../i18n/config";

// Meal List
function* loadMealList({ payload }) {
  if (payload?.mealtype_id == 1) {
    const response1 = yield loadMealListAsync2(payload);
    try {
      if (response1?.data) {
        yield put(
          loadMealListSuccess({
            mealList1: response1?.data?.data,
          })
        );
      } else {
        yield put(loadMealListError(new Error(i18n.t("apiResponse.tryAgain"))));
      }
    } catch (err) {
      yield put(loadMealListError(new Error(i18n.t("apiResponse.tryAgain"))));
    }
  }
  if (payload?.mealtype_id == 2) {
    const response2 = yield loadMealListAsync2(payload);
    try {
      if (response2?.data) {
        yield put(
          loadMealListSuccess({
            mealList2: response2?.data?.data,
          })
        );
      } else {
        yield put(loadMealListError(new Error(i18n.t("apiResponse.tryAgain"))));
      }
    } catch (err) {
      yield put(loadMealListError(new Error(i18n.t("apiResponse.tryAgain"))));
    }
  }

  if (payload?.mealtype_id == 3) {
    const response3 = yield loadMealListAsync3(payload);
    try {
      if (response3?.data) {
        yield put(
          loadMealListSuccess({
            mealList3: response3?.data?.data,
          })
        );
      } else {
        yield put(loadMealListError(new Error(i18n.t("apiResponse.tryAgain"))));
      }
    } catch (err) {
      yield put(loadMealListError(new Error(i18n.t("apiResponse.tryAgain"))));
    }
  }
  if (payload?.mealtype_id == 11) {
    const response4 = yield loadMealListAsync4(payload);
    try {
      if (response4?.data) {
        yield put(
          loadMealListSuccess({
            mealList4: response4?.data?.data,
          })
        );
      } else {
        yield put(loadMealListError(new Error(i18n.t("apiResponse.tryAgain"))));
      }
    } catch (err) {
      yield put(loadMealListError(new Error(i18n.t("apiResponse.tryAgain"))));
    }
  }
}
export function* watchLoadMealList() {
  yield takeEvery(LOAD_MEAL_LIST, loadMealList);
}

// All Meal List
function* loadMealListSingleShort({ payload }) {
  const responses = yield allMealsAsync(payload);
  try {
    if (responses?.data) {
      yield put(mealListSingleShortSuccess(responses?.data?.data));
    } else {
      yield put(mealListSingleShortError(new Error(i18n.t("apiResponse.tryAgain"))));
    }
  } catch (err) {
    yield put(mealListSingleShortError(new Error(i18n.t("apiResponse.tryAgain"))));
  }
}

export function* watchLoadAllMealList() {
  yield takeEvery(LOAD_MEAL_LIST_SINGLE_SHORT, loadMealListSingleShort);
}

// My Meal
function* loadMy_Meal({ payload }) {
  const response = yield loadMyMealAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(loadMyMealSuccess(data.data));
    } else {
      yield put(loadMyMealError(data.error));
    }
  } catch (err) {
    yield put(loadMyMealError(data?.error));
  }
}

export function* watchLoadMyMeal() {
  yield takeEvery(LOAD_MY_MEAL, loadMy_Meal);
}

// Add Meal
function* add_meal({ payload }) {
  const response = yield addMealAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(addMealSuccess(data.data));
      yield put(
        setMessage({
          toastMessage: data?.error_msg,
          code: alrtTypes.SUCCESS,
        })
      );
      payload?.dispatch(loadMealCal(payload?.order_id));
      payload.dispatch(
        loadMyMeals({
          sub_package_id: payload?.sub_package_id,
          order_id: payload?.order_id,
          selected_date: payload?.selected_date,
        })
      );
    } else {
      yield put(addMealError(data.error));
      yield put(
        setMessage({
          toastMessage: data?.error_msg,
          code: alrtTypes.WARNING,
        })
      );
      payload?.dispatch(loadMealCal(payload?.order_id));
    }
  } catch (err) {
    yield put(addMealError(data?.error));
    yield put(
      setMessage({
        toastMessage: data?.error_msg,
        code: alrtTypes.WARNING,
      })
    );
    payload?.dispatch(loadMealCal(payload?.order_id));
  }
}

export function* watchLoadAddMeal() {
  yield takeEvery(ADD_MEAL, add_meal);
}

// Reset user
function* reset_meal() {
  yield put(resetMealSuccess());
}

export function* watchResetMeal() {
  yield takeEvery(RESET_MEAL, reset_meal);
}

// Fav Meal
function* fav_meal({ payload }) {
  const response = yield favMealAsync(payload);
  const { data } = response;
  try {
    if (data.data) {
      yield put(favMealSuccess(payload));
      yield put(
        setMessage({
          toastMessage: data?.error_msg,
          code: alrtTypes.SUCCESS,
        })
      );
    } else {
      yield put(favMealError(data.error));
      yield put(
        setMessage({
          toastMessage: data?.error_msg,
          code: alrtTypes.WARNING,
        })
      );
    }
  } catch (err) {
    yield put(favMealError(data?.error));
    yield put(
      setMessage({
        toastMessage: data?.error_msg,
        code: alrtTypes.WARNING,
      })
    );
  }
}

export function* watchFavMeal() {
  yield takeEvery(FAV_MEAL, fav_meal);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadMealList),
    fork(watchLoadMyMeal),
    fork(watchLoadAddMeal),
    fork(watchLoadAllMealList),
    fork(watchResetMeal),
    fork(watchFavMeal),
  ]);
}
