import {
    TERMS_AND_CONDITIONS,
    TERMS_AND_CONDITIONS_SUCCESS,
    TERMS_AND_CONDITIONS_ERROR,
} from '../Actions';


const INIT_STATE = {
    tc: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TERMS_AND_CONDITIONS:
            return { ...state, loading: true, error: '' };
        case TERMS_AND_CONDITIONS_SUCCESS:
            return { ...state, loading: false, tc: action.payload, error: '' };
        case TERMS_AND_CONDITIONS_ERROR:
            return { ...state, loading: false, tc: null, error: action.payload.message };
        default: return { ...state };
    }
}