import {
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
} from '../Actions';


const INIT_STATE = {
    user: null,
    forgotPassword: '',
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // SignUp
        case CREATE_USER:
            return { ...state, loading: true, error: '' };
        case CREATE_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload, error: '' };
        case CREATE_USER_ERROR:
            return { ...state, loading: false, user: null, error: action.payload.message };

        // Login
        case LOGIN:
            return { ...state, loading: true, error: '' };
        case LOGIN_SUCCESS:
            return { ...state, loading: false, user: action.payload, error: '' };
        case LOGIN_ERROR:
            return { ...state, loading: false, user: null, error: action.payload.message };

        // Forgot Password
        case FORGOT_PASSWORD:
            return { ...state, loading: true, error: '' };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, forgotPassword: action.payload, error: '' };
        case FORGOT_PASSWORD_ERROR:
            return { ...state, loading: false, forgotPassword: null, error: action.payload.message };

        // Reset Password
        case RESET_PASSWORD:
            return { ...state, loading: true, error: '' };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false, resetPassword: action.payload, error: '' };
        case RESET_PASSWORD_ERROR:
            return { ...state, loading: false, resetPassword: null, error: action.payload.message };
        // Reset Password
        case LOGOUT:
            return { ...state, loading: true, error: '' };
        case LOGOUT_SUCCESS:
            return { ...state, user: null, forgotPassword: null, resetPassword: null, loading: false, error: '' };
        case LOGOUT_ERROR:
            return { ...state, loading: false, error: '' };
        default: return { ...state };

    }
}