import {
    LOAD_DASHBOARD,
    LOAD_DASHBOARD_SUCCESS,
    LOAD_DASHBOARD_ERROR,
    LOAD_MY_MEALS,
    LOAD_MY_MEALS_SUCCESS,
    LOAD_MY_MEALS_ERROR,
    RESET_DASHBOARD,
} from '../Actions';

// Dashboard
export const loadDashboard = (user) => ({
    type: LOAD_DASHBOARD,
    payload: user
});
export const loadDashboardSuccess = (user) => ({
    type: LOAD_DASHBOARD_SUCCESS,
    payload: user
});
export const loadDashboardError = (message) => ({
    type: LOAD_DASHBOARD_ERROR,
    payload: { message }
});

export const resetDashboardData = () => ({
    type: RESET_DASHBOARD
});

// myMeals

export const loadMyMeals = (_data) => ({
    type: LOAD_MY_MEALS,
    payload: _data
});
export const loadMyMealsSuccess = (_data) => ({
    type: LOAD_MY_MEALS_SUCCESS,
    payload: _data
});
export const loadMyMealsError = (message) => ({
    type: LOAD_MY_MEALS_ERROR,
    payload: { message }
});
